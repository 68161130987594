import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HospitalService {

  private apiUrl

  constructor(private http:HttpClient,) {
    const useLocal = false;
    this.apiUrl = useLocal ? 'http://localhost:3000/hospital' : 'https://dashboardservice.cephlabs.de/hospital';
   }

   async getHospitals() {
    const url = `${this.apiUrl}/getHospital`;
    const result = await firstValueFrom(this.http.get<any>(url));
    return result;
  }


}
