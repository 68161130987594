import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { HospitalService } from '../service/hospitalService/hospital.service';


interface Hospital {
  Object_key: string;
  name: string;
  only_day_clinic: boolean;
  anzahl_betten_total: number;
  ambulant_fallzahlen_total: number;
  stationaer_fallzahlen_total: number;
  anzahl_fachabteilungen: number;
  geometry: {
    type: string;
    coordinates: number[];
  };
}

@Component({
  selector: 'app-krankenhausreform',
  templateUrl: './krankenhausreform.component.html',
  styleUrls: ['./krankenhausreform.component.css']
})
export class KrankenhausreformComponent implements OnInit{

  map:any;
  isLoading:boolean=false;
  MarkerArray:any[]=[];

  constructor(
    private hospitalService:HospitalService
  )
  {}

  async ngOnInit(): Promise<void> {
    this.initMap();
    await this.getHospitals()
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [51.165195078, 11.97547],
      zoom: 13,
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);
  }

  async getHospitals(): Promise<void> {
    const hospitals: { success: boolean; data: Hospital[] } = await this.hospitalService.getHospitals();
      console.log(hospitals);
  
      const customIcon = L.icon({
        iconUrl: '../assets/icon/hospital-solid.svg', 
        iconSize: [32, 32], 
        iconAnchor: [16, 32], 
        popupAnchor: [0, -32] 
      });
  
      hospitals.data.forEach((hospital: Hospital) => {
        if (hospital.geometry.type === 'Point') {
          const [lng, lat] = hospital.geometry.coordinates;
          
          let marker = L.marker([lat, lng], { icon: customIcon })
          .addTo(this.map)
          .bindPopup(`
            <b>${hospital.name}</b>
            <br><b>Betten:</b> ${hospital.anzahl_betten_total}
          `);

          this.MarkerArray.push({
            leafletMarker:marker,
            raw: hospital
          })
        }
      });
  }
}
