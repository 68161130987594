import { APP_INITIALIZER,NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {MatGridListModule} from '@angular/material/grid-list';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import { MatTreeModule } from '@angular/material/tree';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MatTabsModule } from '@angular/material/tabs';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { DatahubComponent } from './components/datahub/datahub.component';
import { RaumAnalysenComponent } from './components/raum-analysen/raum-analysen.component';
import { DaseinsvorsorgeComponent } from './components/daseinsvorsorge/daseinsvorsorge.component';
import { SimulationComponent } from './components/simulation-section/virusinfektion/covid/simulation/simulation.component';
import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import { DatahubService } from './service/datahubservice/datahubservice.component';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import {MatListModule} from '@angular/material/list';
import { RegioService } from './service/regioservice/regioservice.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';

import {MatNativeDateModule} from '@angular/material/core';
import { Storageservice } from './service/storageservice-component/storageservice-component.component';
import {MatButtonModule} from '@angular/material/button';
import { TileviewComponent } from './components/tileview/tileview.component';
import { Datahub2Component } from './components/datahub2/datahub2.component';
import { MyCarouselComponent } from './components/my-carousel/my-carousel.component';
import { DataRegioComponent } from './components/datahub2/data-regio/data-regio.component';
import { DataSynthComponent } from './components/datahub2/data-synth/data-synth.component';
import { DataMainComponent } from './components/datahub2/data-main/data-main.component';
import { MatInputModule } from '@angular/material/input';
import { NgChartsModule } from 'ng2-charts';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSliderModule} from '@angular/material/slider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table'  
import {MatRadioModule} from '@angular/material/radio';
import { RaumMainComponent } from './components/raum-analysen/raum-main/raum-main.component';
import { ReachMatrixComponent } from './components/raum-analysen/reach-matrix/reach-matrix.component';
import { ReachConfigComponent } from './components/popup/reach-config/reach-config.component';
import { RoutingserviceComponent } from './service/routingservice/routingservice.component';
import { VorsorgeserviceComponent } from './components/daseinsvorsorge/vorsorgeservice/vorsorgeservice.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CreateNetworkdialogComponent} from './components/datahub2/matsim/create-networkdialog/create-networkdialog.component';
import { NetworksComponent } from './components/datahub2/matsim/networks/networks.component';
import { DatePipe } from '@angular/common';
import { DataSynthOverviewComponent } from './components/datahub2/data-synth-overview/data-synth-overview.component';
import { DataSynthPopComponent } from './components/datahub2/data-synth-pop/data-synth-pop.component';
import { DaseinsvorsorgeOverviewComponent } from './components/daseinsvorsorge/daseinsvorsorge-overview/daseinsvorsorge-overview.component';
import { SimulationService } from './service/simulationservice/simulation.service';
import { NachfrageberechnungComponent } from './components/daseinsvorsorge/nachfrageberechnung/nachfrageberechnung.component';
import { SimulationSectionComponent } from './components/simulation-section/simulation-section.component';
import { VirusinfektionComponent } from './components/simulation-section/virusinfektion/virusinfektion.component';
import { CovidComponent } from './components/simulation-section/virusinfektion/covid/covid.component';
import { EigenesSzenarioComponent } from './components/simulation-section/virusinfektion/covid/eigenes-szenario/eigenes-szenario.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { InfluenzaComponent } from './components/simulation-section/virusinfektion/influenza/influenza.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { InfrastukturComponent } from './components/datahub2/infrastuktur/infrastuktur.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import { DialogYearValueComponent } from './components/datahub2/data-regio/dashboards/dialog-year-value/dialog-year-value.component';
import { DialogColorComponent } from './components/datahub2/data-regio/dashboards/dialog-color/dialog-color.component';
import { DialogBreaksComponent } from './components/datahub2/data-regio/dashboards/dialog-breaks/dialog-breaks.component';
import { DialogDataComponent } from './components/datahub2/data-regio/dashboards/dialog-data/dialog-data.component';
import { DialogSelectionRegionComponent } from './components/datahub2/data-regio/dashboards/dialog-selection-region/dialog-selection-region.component';
import { IndicatorRadarComponent } from './components/datahub2/data-regio/dashboards/indicator-radar/indicator-radar.component';
import { DialogRadarComponent } from './components/datahub2/data-regio/dashboards/dialog-radar/dialog-radar.component';
import {MatStepperModule} from '@angular/material/stepper';
import { DialogSaveComponent } from './components/datahub2/data-regio/dashboards/dialog-save/dialog-save.component';
import { DialogGetDashComponent } from './components/datahub2/data-regio/dashboards/dialog-get-dash/dialog-get-dash.component';
import { DataDocComponent } from './components/datahub2/docs/data-doc/data-doc.component';


import { KeycloakAngularModule, KeycloakService,KeycloakBearerInterceptor  } from 'keycloak-angular';
import { initializeKeycloak} from './init/keycloak.init.factory';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { routes } from './app-routing.module';
import { provideClientHydration } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';

import { LocationAppComponent } from './components/popup/location-app/location-app.component';
import { PopupComponent } from './components/datahub2/docs/popup/popup.component';

import { DialogShareComponent } from './components/datahub2/data-regio/dashboards/dialog-share/dialog-share.component';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';

import { IndikatorenauswahlComponent } from './components/datahub2/data-regio/dashboards/indikatorenauswahl/indikatorenauswahl.component';
import { ReportDialogComponent } from './components/datahub2/data-regio/dashboards/report-dialog/report-dialog.component';
import { NotificationsComponent } from './components/notifications/notifications.component';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
const config: SocketIoConfig = { url: 'https://chatservice.cephlabs.de', options: {} };

import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { AddProjectDialogComponent } from './components/notifications/add-project-dialog/add-project-dialog.component';
import { UserListDialogComponent } from './components/notifications/user-list-dialog/user-list-dialog.component';

import { ResultAppComponent } from './components/raum-analysen/result-app/result-app.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { SelectIndikatorenModalComponent } from './components/datahub2/data-regio/dashboards/select-indikatoren-modal/select-indikatoren-modal.component';
import { MatBadgeModule } from '@angular/material/badge';
import { DashboardsComponent } from './components/datahub2/data-regio/dashboards/dashboards.component';
import { DatahubRessourcenModifizierenComponent } from './components/datahub2/data-regio/datahub-ressourcen-modifizieren/datahub-ressourcen-modifizieren.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SearchResultsComponent } from './search-results/search-results.component';

import { DialogReportComponent } from './components/raum-analysen/dialog-report/dialog-report.component';
import { ResultRasterAppComponent } from './components/raum-analysen/result-raster-app/result-raster-app.component';

import {MatCardModule} from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { DialogVersionComponent } from './components/datahub2/data-regio/dashboards/dialog-version/dialog-version.component';
import { ComparisonPrognoseComponent } from './components/datahub2/data-synth/comparison-prognose/comparison-prognose.component';
import { DataDocLeafletComponent } from './components/datahub2/docs/data-doc-leaflet/data-doc-leaflet.component';
import { ScenarioListComponent } from './components/datahub2/matsim/scenario-list/scenario-list.component';
import { LlamaComponent } from './service/llama/llama.component';
import { HitzesimulationComponent } from './components/simulation-section/hitzesimulation/hitzesimulation.component';
import { DialogDownloadComponent } from './components/raum-analysen/dialog-download/dialog-download.component';
import { GebieteComponent } from './components/datahub2/gebiete/gebiete.component';
import { ShareDialogComponent } from './components/raum-analysen/share-dialog/share-dialog.component';
import { GebieteDialogComponent } from './components/datahub2/gebiete/gebiete-dialog/gebiete-dialog.component';
import { PolarComponent } from './polar/polar.component';
import { MatsimComponent } from './components/datahub2/matsim/matsim.component';
import { MatsimResultsComponent } from './components/datahub2/matsim/matsim-results/matsim-results.component';
import { MatsimSimulationComponent } from './components/datahub2/matsim/matsim-simulation/matsim-simulation.component';
import { PreziComponent } from './prezi/prezi.component';
import { MobilitaetComponent } from './components/simulation-section/mobilitaet/mobilitaet.component';
import { GibietParamsDialogComponent } from './components/datahub2/gebiete/gibiet-params-dialog/gibiet-params-dialog.component';
import { NachfrageberechnungNewComponent } from './components/daseinsvorsorge/nachfrageberechnung-new/nachfrageberechnung-new.component';
import { NachfrageberechnungSelectComponent } from './components/daseinsvorsorge/nachfrageberechnung-select/nachfrageberechnung-select.component';
import { NachfrageberechnungItemComponent } from './components/daseinsvorsorge/nachfrageberechnung-item/nachfrageberechnung-item.component';
import { NachfrageberechnungEditComponent } from './components/daseinsvorsorge/nachfrageberechnung-edit/nachfrageberechnung-edit.component';
import { SchulEntiwicklungPlanungComponent } from './components/schul-entiwicklung-planung/schul-entiwicklung-planung.component';
import { PopupDialogComponent } from './components/datahub2/data-synth/comparison-prognose/popup-dialog/popup-dialog.component';
import { SynthIndiDialogComponent } from './components/datahub2/data-synth/synth-indi-dialog/synth-indi-dialog.component';
import { NvigationBoxComponent } from './nvigation-box/nvigation-box.component';
import { NotfallinfrastrukturComponent } from './components/daseinsvorsorge/notfallinfrastruktur/notfallinfrastruktur.component';
import { NotfallinfrastrukturItemComponent } from './components/daseinsvorsorge/notfallinfrastruktur-item/notfallinfrastruktur-item.component';
import { NotfallinfrastrukturEditComponent } from './components/daseinsvorsorge/notfallinfrastruktur-edit/notfallinfrastruktur-edit.component';
import { AiChartComponent } from './components/ai-chart/ai-chart.component';
import { TransitDialogComponent } from './components/schul-entiwicklung-planung/transit-dialog/transit-dialog.component';
import { AddRouteDialogComponent } from './components/schul-entiwicklung-planung/add-route-dialog/add-route-dialog.component';
import { AddRouteDisplayComponent } from './components/schul-entiwicklung-planung/add-route-display/add-route-display.component';
import { SchulplanungComponent } from './components/schulplanung/schulplanung.component';
import { StartDialogComponent } from './components/schulplanung/schulplanung-dialog/start-dialog/start-dialog.component';
import { GefahranalyseComponent } from './components/schulplanung/schulplanung-dialog/gefahranalyse/gefahranalyse.component';
import { SaveDialogComponent } from './components/schulplanung/schulplanung-dialog/save-dialog/save-dialog.component';
import { SchulplanungGymComponent } from './components/schulplanung/schulplanung-gym/schulplanung-gym.component';
import { AnalyseGymComponent } from './components/schulplanung/schulplanung-dialog/analyse-gym/analyse-gym.component';
import { DeleteGymComponent } from './components/schulplanung/schulplanung-dialog/delete-gym/delete-gym.component';
import { SchulplanungPrimComponent } from './components/schulplanung/schulplanung-prim/schulplanung-prim.component';
import { SchulplanungOverviewComponent } from './components/schulplanung/schulplanung-overview/schulplanung-overview.component';
import { RasterSchulenComponent } from './components/schul-entiwicklung-planung/raster-schulen/raster-schulen.component';
import { LoadingOverlayComponent } from './components/schul-entiwicklung-planung/raster-schulen/loading-overlay.component';
import { FahrplanDialogComponent } from './components/schul-entiwicklung-planung/fahrplan-dialog/fahrplan-dialog.component';
import { CompMobilityDialogComponent } from './components/schul-entiwicklung-planung/comp-mobility-dialog/comp-mobility-dialog.component';
import { UmbauMobilityDialogComponent } from './components/schul-entiwicklung-planung/umbau-mobility-dialog/umbau-mobility-dialog.component';
import { MobiAnalyseComponent } from './components/schulplanung/schulplanung-dialog/mobi-analyse/mobi-analyse.component';
import { ViviComponent } from './components/vivi/vivi.component';
import { MarkdownModule } from 'ngx-markdown';
import { LocationPickerComponent } from './reusable-components/location-picker/location-picker.component';
import { KrankenhausreformComponent } from './krankenhausreform/krankenhausreform.component';
import { WarningDialogThemaDatahubComponent } from './warning-dialog-thema-datahub/warning-dialog-thema-datahub.component';




@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    DatahubComponent,
    RaumAnalysenComponent,
  
    SimulationComponent,
    MainComponent,
    LoginComponent,
    MatsimResultsComponent,
    MatsimSimulationComponent,
    NetworksComponent,
    CreateNetworkdialogComponent,
    DatahubService,
    RegioService,
    TileviewComponent,
    Datahub2Component,
    MyCarouselComponent,
    DataRegioComponent,
    DataSynthComponent,
    DataMainComponent,
    RaumMainComponent,
    ReachMatrixComponent,
    ReachConfigComponent,
    RoutingserviceComponent,
    VorsorgeserviceComponent,
    DataSynthOverviewComponent,
    DataSynthPopComponent,
    DaseinsvorsorgeOverviewComponent,
    NachfrageberechnungComponent,
    SimulationSectionComponent,
    VirusinfektionComponent,
    CovidComponent,
    EigenesSzenarioComponent,
    InfluenzaComponent,
    InfrastukturComponent,
    DialogYearValueComponent,
    DialogColorComponent,
    DialogBreaksComponent,
    DialogDataComponent,
    DialogSelectionRegionComponent,
    IndicatorRadarComponent,
    DialogRadarComponent,
    DialogSaveComponent,
    DialogGetDashComponent,
    DataDocComponent,
    DataDocLeafletComponent,
    LocationAppComponent,
    PopupComponent,
    DialogShareComponent,
    IndikatorenauswahlComponent,
    ReportDialogComponent,
    NotificationsComponent,
    AddProjectDialogComponent,
    UserListDialogComponent,
    ResultAppComponent,
    ResultRasterAppComponent,
    SelectIndikatorenModalComponent,
    DashboardsComponent,
    DatahubRessourcenModifizierenComponent,
    SidenavComponent,
    SearchResultsComponent,
    DialogReportComponent,
    ErrorDialogComponent,
    DialogVersionComponent,
    ComparisonPrognoseComponent,
    ScenarioListComponent,
    HitzesimulationComponent,
    DialogDownloadComponent,
    GebieteComponent,
    ShareDialogComponent,
    GebieteDialogComponent,
    NachfrageberechnungNewComponent,
    NachfrageberechnungSelectComponent,
    NachfrageberechnungItemComponent,
    PolarComponent,
    MatsimComponent,
    PreziComponent,
    MobilitaetComponent,
    GibietParamsDialogComponent,
    NachfrageberechnungEditComponent,
    SchulEntiwicklungPlanungComponent,
    PopupDialogComponent,
    SynthIndiDialogComponent,
    NvigationBoxComponent,
    DaseinsvorsorgeComponent,
    NotfallinfrastrukturComponent,
    NotfallinfrastrukturItemComponent,
    NotfallinfrastrukturEditComponent,
    AiChartComponent,
    TransitDialogComponent,
    AddRouteDialogComponent,
    AddRouteDisplayComponent,
    SchulplanungComponent,
    StartDialogComponent,
    GefahranalyseComponent,
    SaveDialogComponent,
    SchulplanungGymComponent,
    AnalyseGymComponent,
    DeleteGymComponent,
    SchulplanungPrimComponent,
    SchulplanungOverviewComponent,
    RasterSchulenComponent,
    
    LoadingOverlayComponent,
          FahrplanDialogComponent,
          CompMobilityDialogComponent,
          UmbauMobilityDialogComponent,
          MobiAnalyseComponent,
          ViviComponent,
          LocationPickerComponent,
          KrankenhausreformComponent,
          WarningDialogThemaDatahubComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    MarkdownModule.forRoot() ,// Initialisierung des Markdown-Moduls

    NgChartsModule, 
    MatNativeDateModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatRadioModule,
    MatFormFieldModule, 
    MatProgressBarModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatInputModule,
    HttpClientModule, 
    MatButtonModule,
    AppRoutingModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatListModule, 
    FormsModule, 
    MatSelectModule,
    MatSlideToggleModule,
    LeafletModule,
    MatIconModule,
    MatTabsModule,
    MatGridListModule,
    MatCheckboxModule,  
    BrowserAnimationsModule,
    MatTableModule, 
    MatButtonToggleModule,
    MatDatepickerModule,
    TypeaheadModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDialogModule,
    MatStepperModule,
    MatProgressBarModule,
    MatSortModule,
    MatPaginatorModule,
    SocketIoModule.forRoot(config),
    PickerModule,
    NotifierModule,
    MatBadgeModule,
    MatSidenavModule,
    MatTreeModule,
    MatCardModule,
    MatRippleModule,
  ],
  providers: [
    DatahubService, 
    LlamaComponent,
    HttpClient,
    RegioService, 
    DataDocLeafletComponent,
    ReachConfigComponent, 
    Storageservice,  
    LocationAppComponent, 
    MatSnackBar,
    RoutingserviceComponent, 
    VorsorgeserviceComponent,
    DatePipe,
    CookieService,
    SimulationService,
    provideRouter(routes),
    provideClientHydration(),
    //provideAnimationsAsync(),
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true
    },
    provideHttpClient(
      withInterceptorsFromDi() // tell httpClient to use interceptors from DI
    ),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
