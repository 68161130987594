import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import * as L from 'leaflet';
import { latLng, tileLayer } from 'leaflet';
import { RegioService } from '../../../../service/regioservice/regioservice.component';
import { Storageservice } from '../../../../service/storageservice-component/storageservice-component.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DatahubService } from '../../../../service/datahubservice/datahubservice.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import * as XLSX from 'xlsx';
import MapLibreGL from 'maplibre-gl';
import { SimulationService } from 'src/app/service/simulationservice/simulation.service';
import html2canvas from 'html2canvas';
import 'leaflet.browser.print/dist/leaflet.browser.print.min.js';
import { Observable, Subscription, endWith, map, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';
declare var require: any
const classyBrew = require('classybrew');
//import classyBrew from 'classybrew' ;
import { MatDialog } from '@angular/material/dialog';
import { DialogYearValueComponent } from './dialog-year-value/dialog-year-value.component';
import { RequestBuilder } from 'src/app/modals/requestBilder.class';
import { DialogColorComponent } from './dialog-color/dialog-color.component';
import { DialogBreaksComponent } from './dialog-breaks/dialog-breaks.component';
import { DialogDataComponent } from './dialog-data/dialog-data.component';
import { DialogSelectionRegionComponent } from './dialog-selection-region/dialog-selection-region.component';

import { IndicatorRadarComponent } from './indicator-radar/indicator-radar.component';
import { DialogSaveComponent } from './dialog-save/dialog-save.component';
import { DialogGetDashComponent } from './dialog-get-dash/dialog-get-dash.component';
import { DialogShareComponent } from './dialog-share/dialog-share.component';
//import introJs from 'intro.js';
import * as htmlToImage from 'html-to-image';
import { ReportDialogComponent } from './report-dialog/report-dialog.component';
import { SelectIndikatorenModalComponent } from './select-indikatoren-modal/select-indikatoren-modal.component';
import { MatTabGroup } from '@angular/material/tabs';
import { DialogRadarComponent } from './dialog-radar/dialog-radar.component';
import { DashboardService } from 'src/app/service/dashboardService/dashboard.service';
import { DialogVersionComponent } from './dialog-version/dialog-version.component';
import { WarningDialogThemaDatahubComponent } from 'src/app/warning-dialog-thema-datahub/warning-dialog-thema-datahub.component';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.css'],
})
export class DashboardsComponent implements OnInit,AfterViewInit{

//nico
selectMode = false;
selectedShape!: any ;
topicsLoaded: any = false;
chartReady: any = false;
chartLegend: any[] = [];
chartRootData: any = [];
selectedMinYear: any = undefined;
selectedMaxYear: any = undefined;
selectedMinVal: any = undefined;
selectedMaxVal: any = undefined;
timeHasBeen: boolean = false;
selectedObject: any;
layers: any = [];
map: any;
public selectedLevel!: number;
spinner: any = false;
selectedTopic: any = [];
keys: any[] = [];
regioMetaData: any = [];
regioData: any[] = [];
legend: any;
legendCluster: any;
showIndi: any = false;
showAnal: boolean = true;
showDetail: boolean = false;
presLayer: any = [];
result: ArrayBuffer | undefined;
selectedIndicators: any = [];
selectedChart: any;
selectedChartIndex: any = 0;
selectedChartTitle = 'ABc';
options = {
  layers: [
    tileLayer('https://m.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: '...',
    }),
  ],
  zoom: 12,
  minZoom: 7,
  center: latLng(51.04962, 12.1369),
};
testThemen: any;
center: L.LatLng = latLng(51.04962, 12.1369);
chart: any;
charts: any = [];
maxYear!: any;
minYear!: any;
minValue!: any;
maxValue!: any;
selectedIndex: any;
indi: any[] = [];
chartGeneral: any;
chartAgg: any;
chartChange: any;
chartTime: any;
selectedChartObject!: any;
info: any;
//Ahmed
convertToJson: any;
upload: any ={};
fileName = '';
minYearData: any = 2000;
maxYearData: any = 2024;
minYearData2: any = 2022;
maxYearData2: any = 2024;
selectedLands: { [key: string]: boolean } = {};
selectedLandsVerband: { [key: string]: boolean } = {};
selectedLandsGmeinde: { [key: string]: boolean } = {};
selectedLandsRadio: { [key: string]: boolean } = {};
sliderValue!: number;
dataEx!: any[];
downloaded = false;
showExport = false;
showUpload = false;
mapSmall: any;
sachsenAnhaltShape: any;
checkLayer: any;
firstRender = false;
firstRenderVerband = false;
firstRenderGemeinde = false;
customData = false;
customPath = false;
datahubPath: number=0;
dataIm = false;
dataEr = false;
auswahlClicked = false;
land: Number = 1;
selectedLandV: any;
mapSmallVerband: any;
verd: any;
verband: any;
mapSmallGmeinde: any;
gemeinde: any;
stepCounter: any;
chooseThema = false;
importValues = false;
uploded = false;
ThemaViaEr = false;
newTopicColor: string = '';
editPen = false;
showDropdown = false;
setBreaks: any='';
filteredOptions: Observable<any[]> | undefined;
myControl = new FormControl();
chartApplyOnMap = true;
newYear: any;
chartType = 'bar';
breaksAmount: any = 4;
inputBesch: any;
inputIndi: any = '';
inputUnit: any;
inputThema: any = '';
indiData: any;
filteredIndiData: any;
dataIndiTable: any;
dataIndiTableValues: any;
loading = true;
loadingEr = false;
selectLandkreis = [
  'Harz',
  'Halle (Saale)',
  'Dessau-Rosslau',
  'Magdeburg',
  'Altmarkkreis Salzwedel',
  'Anhalt-Bitterfeld',
  'Boerde',
  'Burgenlandkreis',
  'Jerichower Land',
  'Mansfeld-Suedharz',
  'Saalekreis',
  'Salzlandkreis',
  'Stendal',
  'Wittenberg',
];
keytable = {
  'Harz': '15085',
  'Halle (Saale)': '15002',
  'Dessau-Rosslau': '15001',
  'Magdeburg': '15003',
  'Altmarkkreis Salzwedel': '15081',
  'Anhalt-Bitterfeld': '15082',
  'Boerde': '15083',
  'Burgenlandkreis': '15084',
  'Jerichower Land': '15086',
  'Mansfeld-Suedharz': '15087',
  'Saalekreis': '15088',
  'Salzlandkreis': '15089',
  'Stendal': '15090',
  'Wittenberg': '15091',
};
shapesRendered = 0;
filteredTopics!: any[];
filteredIndi!: any[];
topicFilter: string = '';
indiFilter: string = '';
selectedCheckbox: any;
displayedColumns: any = ['Gebietsname', 'years', 'data'];
dataSource!: any[];
noIndikatorenText = false;
showDropdown2 = false;
valuesArray: any = [];
valuesBreaks: any[] = [];
valuesStored: any[] = [];
customBreaks = false;
leafletImage: any;
filterGemeinde:any
searchForGemeinde:any
showColor=false;
showBreaks=false;
showTimeAndValue=false;
showFilterRegion=false;
showMapDownload=false;
red: number = 0;
green: number = 0;
blue: number = 0;
color1: string = '#ff0000';
color2: string = '#00ff00';
colorScheme: string[] = [];
setColor=false;
viridisCustom=['#440154','#3E4989','#31688E','#26828E','#1F9E89','#35B779','#B5DE2B','#FDE725'];
chosenBreaks:number=5;
nextYear: any;
chartLegendMap!: any;
selectModeMap=false;
selectedMinYearMap: any = undefined;
selectedMaxYearMap: any = undefined;
selectedMinValMap: any = undefined;
selectedMaxValMap: any = undefined;
showDownloadChart=false;
showTable=false;
showShareChart=false;
selectedChipIndex = 0;
indiForErTable:any;
auswahlEnded=false;
activateRadar!:any;
currentState!:any;
@ViewChild('childComponent') childComponent!: IndicatorRadarComponent;
radarYears:any=[
{   radarMinYear:0,
    radarYear:0,
    radarStartIntervalle:0,
    radarEndIntervalle:0,
    yearsArray:[]}
];
selectedRegionForRadar:any
radarChartIndex:any
lastClickedAction: string = 'Allgemein';
radarActivated=false;
showSave=false;
//after save//
selectedButton: any = null; 
newSelection=false;
topicsLandkreis: any[]= [];
topicsVerbandsgemeinde:any[]= [];
topicsGemeinde:any[]= [];
bundeslandLayer:any;
centerBundesland:any;
@ViewChild('tabGroup') tabGroup!: MatTabGroup;
previousTabs: string[] = [];
needDialog=false;
dialogTitle = 'Fehler';
dialogMessage = 'Bevor Sie das Dashboard freigeben können, müssen Sie es zunächst speichern.';
showVersion = false;
checkVersionHistory=false;
showApplyOnMap=false;
showDoNotApplyOnMap=false;
neighbours: any[] = [];
quellentable:any
quelle:any

  constructor(
    private storageService: Storageservice,
    private regioService: RegioService,
    private router: Router,
    private route:ActivatedRoute,
    private simulationService: SimulationService,
    public dialog: MatDialog,
    private datahubService: DatahubService,
    private dashboardService:DashboardService,
    //private cdr: ChangeDetectorRef
  ) {
        this.route.params.subscribe((params:any) => {
      if (Object.keys(params).length !== 0) {

        this.checkVersionHistory=true
        this.dashboardService.getDashboard(params['id'],params['version']).subscribe(async (fullDashboard:any) => {
          this.storageService.setObject(fullDashboard.data.Object);
        })
      }else{
        if (this.storageService.getObject().Logo_URL == '') {
          alert('Bitte zuerst Landkreis festlegen!');
          this.router.navigateByUrl('/start');
        } else this.setLayer();
      }
     })
     
  }

  async ngOnInit() {
    this.quellentable = await this.regioService.getQuellen();
    await this.createAreaArrayTopics(3);
    await this.createAreaArrayTopics(4);
    await this.createAreaArrayTopics(5);

    this.route.params.subscribe((params:any) => {
      if (Object.keys(params).length !== 0) {

        this.dashboardService.getDashboard(params['id'],params['version']).subscribe(async (fullDashboard:any) => {
          this.storageService.setObject(fullDashboard.data.Object);
          await this.setKeys(parseInt(fullDashboard.data.selectedLevel),fullDashboard.data.Object);
          await this.getRegioMEtaData(fullDashboard.data.dummyRegioMetaDataIndicators,fullDashboard.data.topicsLandkreis,fullDashboard.data.topicsVerbandsgemeinde,fullDashboard.data.topicsGemeinde);
          fullDashboard.data.regioMetaData=this.regioMetaData;
          fullDashboard.data.topicsLandkreis=this.topicsLandkreis; 
          fullDashboard.data.topicsVerbandsgemeinde=this.topicsVerbandsgemeinde; 
          fullDashboard.data.topicsGemeinde=this.topicsGemeinde;     
          this.loadDataRegio2(fullDashboard.data)
        })
      } 
     })

    this.selectLandkreis.forEach((land) => {this.selectedLands[land] = false;});
    this.verband = await this.regioService.getShapefileByIdAndLevel(this.storageService.getObject().Object_Key,4);
    this.verband = this.verband[0];
    this.verband.forEach((land: any) => {this.selectedLandsVerband[land.Object_Nam] = false;});
    this.gemeinde = await this.regioService.getShapefileByIdAndLevel(this.storageService.getObject().Object_Key,5);
    this.gemeinde = this.gemeinde[0];
    this.gemeinde.forEach((land: any) => {this.selectedLandsGmeinde[land.Object_Nam] = false;});

    this.filteredOptions = this.myControl.valueChanges.pipe(startWith(''),
      map((value) => this._filter(value))
    );



  }

  ngAfterViewInit() {
    this.updatePreviousTabs();
  }

  async hideNearbyGebiete() {
     this.neighbours = [];
     this.auswahl()
  }

  addQuelleToIndikator(indikator: any): void {
    const sourceTable = this.quellentable[0];
    const sourceKey = indikator.Source.trim();
  
    for (const key in sourceTable) {
      if (Object.prototype.hasOwnProperty.call(sourceTable, key)) {
        if (sourceKey === key.trim()) {
          this.quelle = sourceTable[key];
          return;
        }
      }
    }
  
    this.quelle = 'Source not found';
  }
  

  async showNearbyGebiete() {
    

   var obj = this.storageService.getObject()
   if(obj) {
    var r = await this.datahubService.getNeighbors( obj.Object_Key)
    if(r) {
      this.neighbours = r
      this.neighbours.push(obj.Object_Key)
      this.auswahl()
      return
    
    }

   }

    
    }
  
  updatePreviousTabs() {
  if(this.tabGroup)  this.previousTabs = this.tabGroup._tabs.map(tab => tab.textLabel);
  }
  
  switchToNewestTab() {
    const tabGroup = this.tabGroup;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
  
    const currentTabs = tabGroup._tabs.map(tab => tab.textLabel);
    const newTab = currentTabs.find(tab => !this.previousTabs.includes(tab));
    
    if (newTab) {
      const newIndex = currentTabs.indexOf(newTab);
      tabGroup.selectedIndex = newIndex;
      this.updatePreviousTabs();
    }
  }

  async createAreaArrayTopics(selectedLevel:any){
    var objKey = this.storageService.getObject().Object_Key;
    if (selectedLevel == 3) objKey = '15';
    var res = await this.regioService.getShapefileByIdAndLevel(objKey,selectedLevel) as any;
    this.keys = [];
    var keys: any[] = [];

    
    res[0].forEach((element: any) => {keys.push(element.Object_Key);});
    this.keys = keys;
    this.regioMetaData = [];
    var regioMeta: any = [];
    // var randomElement = this.keys[Math.floor(Math.random() * this.keys.length)];
    // var randomElement1 =this.keys[Math.floor(Math.random() * this.keys.length)];
    // var randomElement2 =this.keys[Math.floor(Math.random() * this.keys.length)];
    // this.keys = [randomElement, randomElement1, randomElement2];

    var regioData = await this.regioService.getRegioDataByKey(this.keys) as any;
    this.regioData = regioData;
    regioMeta = await this.regioService.getIndicatorMeta();

    var indi = regioData.map(function (el: { Key: any }) {return el.Key;});
    const uniqueIndi = [...new Set(indi)];
    this.regioMetaData = regioMeta.data;
    regioMeta.data.forEach((bereich: { data: any[]; visible: boolean }) => {
      var filtered = bereich.data.filter((indi) => {
        var key = indi.Source + indi.Tabellen_ID;
        return uniqueIndi.indexOf(key) > -1;
      });
      bereich.data = filtered;
      if (bereich.data.length > 0) bereich.visible = true;
      else bereich.visible = false;
    });
    this.regioMetaData = regioMeta.data.filter((el:any) => el.visible);

    if (selectedLevel==3)  this.topicsLandkreis=this.regioMetaData;
    else if (selectedLevel==4)  this.topicsVerbandsgemeinde=this.regioMetaData;
    else if (selectedLevel==5)  this.topicsGemeinde=this.regioMetaData;
  }

  async setKeys(selectedLevel:any,Object:any){
    var objKey = Object.Object_Key;
    if (selectedLevel == 3) objKey = '15';
    var res = await this.regioService.getShapefileByIdAndLevel(objKey,selectedLevel) as any;
    this.keys = [];
    var keys: any[] = [];
    res[0].forEach((element: any) => {keys.push(element.Object_Key);});
    this.keys = keys;
  }

  async getRegioMEtaData(dummyRegioMetaDataIndicators:any,topicsLandkreisVar:any,topicsVerbandsgemeindeVar:any,topicsGemeindeVar:any){
    this.regioMetaData = [];
    var regioMeta: any = [];
    // var randomElement = this.keys[Math.floor(Math.random() * this.keys.length)];
    // var randomElement1 =this.keys[Math.floor(Math.random() * this.keys.length)];
    // var randomElement2 =this.keys[Math.floor(Math.random() * this.keys.length)];
    // this.keys = [randomElement, randomElement1, randomElement2];
  
    var regioData = await this.regioService.getRegioDataByKey(this.keys) as any;

    this.regioData = regioData;
    regioMeta = await this.regioService.getIndicatorMeta();

    var indi = regioData.map(function (el: { Key: any }) {return el.Key;});
    const uniqueIndi = [...new Set(indi)];
    this.regioMetaData = regioMeta.data;
    regioMeta.data.forEach((bereich: { data: any[]; visible: boolean }) => {
      const filtered = bereich.data.filter((indi) => {
        var key = indi.Source + indi.Tabellen_ID;
        return uniqueIndi.indexOf(key) > -1;
      });
      bereich.data = filtered;
      if (bereich.data.length > 0) bereich.visible = true;
      else bereich.visible = false;
    });
    this.regioMetaData = regioMeta.data.filter((el:any) => el.visible);

    let checkedNames = dummyRegioMetaDataIndicators.map((item:any) => item.Bereich);
    let indicatorNames = dummyRegioMetaDataIndicators.flatMap((item:any) => item.data).map((indicator:any) => indicator.Name);

    this.regioMetaData.forEach((theme:any) => {
    if (checkedNames.includes(theme.Bereich)) {
        theme.checked = true;
    }
    theme.data.forEach((indicator:any) => {
        if (indicatorNames.includes(indicator.Name)) {
            indicator.checked = true;
        }
      });
    });
 
    let checkedNames2 = topicsLandkreisVar.map((item:any) => item.Bereich);
    let indicatorNames2 = topicsLandkreisVar.flatMap((item:any) => item.data).map((indicator:any) => indicator.Name);
    this.topicsLandkreis.forEach((theme:any) => {
    if (checkedNames2.includes(theme.Bereich)) {
        theme.checked = true;
    }
    theme.data.forEach((indicator:any) => {
        if (indicatorNames2.includes(indicator.Name)) {
            indicator.checked = true;
        }
      });
    });

    let checkedNames3 = topicsVerbandsgemeindeVar.map((item:any) => item.Bereich);
    let indicatorNames3 = topicsVerbandsgemeindeVar.flatMap((item:any) => item.data).map((indicator:any) => indicator.Name);
    this.topicsVerbandsgemeinde.forEach((theme:any) => {
    if (checkedNames3.includes(theme.Bereich)) {
        theme.checked = true;
    }
    theme.data.forEach((indicator:any) => {
        if (indicatorNames3.includes(indicator.Name)) {
            indicator.checked = true;
        }
      });
    });

    let checkedNames4 = topicsGemeindeVar.map((item:any) => item.Bereich);
    let indicatorNames4 =topicsGemeindeVar.flatMap((item:any) => item.data).map((indicator:any) => indicator.Name);
    this.topicsGemeinde.forEach((theme:any) => {
    if (checkedNames4.includes(theme.Bereich)) {
        theme.checked = true;
    }
    theme.data.forEach((indicator:any) => {
        if (indicatorNames4.includes(indicator.Name)) {
            indicator.checked = true;
        }
      });
    });
  }

  createRequestBody(){      
    let requestBuilder = new RequestBuilder(
      this.chartLegend,
      this.selectedMinYear,
      this.selectedMaxYear,
      this.selectedMinVal,
      this.selectedMaxVal,
      this.selectedShape,
      this.selectedLevel,
      this.breaksAmount,
      this.datahubPath,
      this.setColor,
      this.colorScheme,
      this.storageService,
      this.selectedChartObject,
      this.neighbours,
      this.upload,
    );


    return requestBuilder
  }

  
  chartTemplate(testData:any,yAxisLabel:any){
    const monthMap:any = {
      '01': 'Januar',
      '02': 'Februar',
      '03': 'März',
      '04': 'April',
      '05': 'Mai',
      '06': 'Juni',
      '07': 'Juli',
      '08': 'August',
      '09': 'September',
      '10': 'Oktober',
      '11': 'November',
      '12': 'Dezember',
    };

    const formattedLabels = testData.chart.chartData.labels.map((label:any) => {
      const labelStr = label.toString();
      if (labelStr.length === 4) {
        return labelStr; 
      } else if (labelStr.length === 6) {
        const year = labelStr.slice(0, 4);
        const month = labelStr.slice(4, 6);
        return `${year} ${monthMap[month] || ''}`;
      }
      return labelStr;
    });
    return  {
      type: this.chartType,
      data: {
        labels: formattedLabels,
        datasets: testData.chart.chartData.datasets,
      },
      options: {
        scales: {
          y: {
            title: {
              display: true,
              text: yAxisLabel,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  }

  async showCompareChart() {


    var requestBody = this.createRequestBody().buildRequestObject();
    var testData = await this.regioService.getCompareChartData(requestBody) as any;

    await this.getApiChartData();
    this.apiChartData=testData

    this.setMinMax(testData.chart.chartMeta);
    this.chartGeneral?.destroy();

    testData.chart.chartData.datasets.forEach((item: any) => {
      item.skipNull = true;
      item.data.forEach((element: any, index: number) => {
        if (element === 0) {
          item.data[index] = null;
          if (index != 1) {
            item.barThickness = 10;
          }
        }
      });
    });

    var yAxisLabel = 'Durchschnitt ' +testData.chart.chartMeta.minChartYear +' - ' + testData.chart.chartMeta.maxChartYear;
    var chartDisplay = this.chartTemplate(testData,yAxisLabel)
    setTimeout(() => {this.chartGeneral = new Chart('canvasGeneral', chartDisplay); }, 10);
    this.chartLegend = testData.chart.chartMeta.regions;
    if(this.selectedLevel!=5)this.chartLegend.sort((a, b) => a.label.localeCompare(b.label));

    if (this.chartApplyOnMap) {
      let requestBuilderMap = new RequestBuilder(this.chartLegend,this.selectedMinYear,this.selectedMaxYear,this.selectedMinVal, this.selectedMaxVal,this.selectedShape,this.selectedLevel,this.breaksAmount,this.datahubPath,this.setColor,this.colorScheme,this.storageService,this.selectedChartObject,this.upload,);
      var testData2 = await this.regioService.getCompareChartData(requestBuilderMap.buildRequestObject()) as any;
      this.chartLegendMap = testData2.chart.chartMeta.regions

      this.addShapelayers(testData.shapefiles.data);
      this.addLegendNew(testData, 'Vergleich');
    }
  }

  async showYearlyChangeChart() {
    var yAxisLabel = '%';
    var requestBody = this.createRequestBody().buildRequestObject();
    var testData = await this.regioService.getYearlyChangeChartData(requestBody) as any;
    await this.getApiChartData();
    this.apiChartData=testData

    let storedArray = testData.shapefiles.data.map((item: any) => {return item.value;});
    this.valuesStored = storedArray.filter((item: any) => {return item !== undefined;});
  
    this.setMinMax(testData.chart.chartMeta);
    this.chartGeneral?.destroy();
    var chartDisplay = this.chartTemplate(testData,yAxisLabel)
    setTimeout(() => {
      this.chartGeneral = new Chart('canvasGeneral', chartDisplay);
    }, 10);
    this.chartLegend = testData.chart.chartMeta.regions;
    this.chartLegend.sort((a, b) => a.label.localeCompare(b.label));

    if (this.chartApplyOnMap) {
      let requestBuilderMap = new RequestBuilder(this.chartLegend,this.selectedMinYear,this.selectedMaxYear,this.selectedMinVal, this.selectedMaxVal,this.selectedShape,this.selectedLevel,this.breaksAmount,this.datahubPath,this.setColor,this.colorScheme,this.storageService,this.selectedChartObject,this.upload,);
      var testData2 = await this.regioService.getYearlyChangeChartData(requestBuilderMap.buildRequestObject()) as any;
      this.chartLegendMap = testData2.chart.chartMeta.regions
      this.addShapelayers(testData.shapefiles.data);
      this.addLegendNew(testData,'Jährliche Veränderungsrate ' +testData.chart.chartData.labels[testData.chart.chartData.labels.length - 1]);
    }
  }

  async showGeneralChart() {
    var yAxisLabel = this.selectedChartObject? this.selectedChartObject.unit: ' ';
    var requestBody = this.createRequestBody().buildRequestObject();

    var testData = await this.regioService.getGeneralChartData(requestBody) as any;

    await this.getApiChartData();
    this.apiChartData=testData

    //import for the first click on edit in map
    let storedArray = testData.shapefiles.data.map((item: any) => {return item.value;});
    this.valuesStored = storedArray.filter((item: any) => {return item !== undefined;});
   
    this.setMinMax(testData.chart.chartMeta);
    this.chartGeneral?.destroy();
    var chartDisplay = this.chartTemplate(testData,yAxisLabel)
    setTimeout(() => {this.chartGeneral = new Chart('canvasGeneral', chartDisplay);}, 10);
    this.chartLegend = testData.chart.chartMeta.regions;
    if(this.selectedLevel!=5)this.chartLegend.sort((a, b) => a.label.localeCompare(b.label));

    if (this.chartApplyOnMap) {
      let requestBuilderMap = new RequestBuilder(this.chartLegend,this.selectedMinYear,this.selectedMaxYear,this.selectedMinVal, this.selectedMaxVal,this.selectedShape,this.selectedLevel,this.breaksAmount,this.datahubPath,this.setColor,this.colorScheme,this.storageService,this.selectedChartObject,this.upload,);
      var testData2 = await this.regioService.getGeneralChartData(requestBuilderMap.buildRequestObject()) as any;
      this.chartLegendMap = testData2.chart.chartMeta.regions

      this.addShapelayers(testData.shapefiles.data);
      this.addLegendNew(testData, 'Allgemein ' +testData.chart.chartData.labels[testData.chart.chartData.labels.length - 1]);
    }
  }

  async showChangeRateChart() {
    var requestBody = this.createRequestBody().buildRequestObject();
    var testData = await this.regioService.getChangeRateChart(requestBody) as any;
    await this.getApiChartData();
    this.apiChartData=testData
    var yAxisLabel = testData.chart.chartData.labels[0] + 'in %';

    let storedArray = testData.shapefiles.data.map((item: any) => {return item.value;});
    this.valuesStored = storedArray.filter((item: any) => {return item !== undefined;});
    
    this.setMinMax(testData.chart.chartMeta);
    this.chartGeneral?.destroy();
    var chartDisplay = this.chartTemplate(testData,yAxisLabel)
    setTimeout(() => {this.chartGeneral = new Chart('canvasGeneral', chartDisplay);}, 10);

    this.chartLegend = testData.chart.chartMeta.regions;
    this.chartLegend.sort((a, b) => a.label.localeCompare(b.label));
    if (this.chartApplyOnMap) {
      let requestBuilderMap = new RequestBuilder(this.chartLegend,this.selectedMinYear,this.selectedMaxYear,this.selectedMinVal, this.selectedMaxVal,this.selectedShape,this.selectedLevel,this.breaksAmount,this.datahubPath,this.setColor,this.colorScheme,this.storageService,this.selectedChartObject,this.upload,);
      var testData2 = await this.regioService.getChangeRateChart(requestBuilderMap.buildRequestObject()) as any;
      this.chartLegendMap = testData2.chart.chartMeta.regions
      this.addShapelayers(testData.shapefiles.data);
      this.addLegendNew(testData,testData.chart.chartData.labels[0]);
    }
  }

  async deselectAll(bool: any) {
    this.chartApplyOnMap=this.getChartApplyOnMapStatus();    
    this.selectMode = bool;
    this.chartLegend.forEach((element) => {element.disabled = bool;});
    await this.updateChartBasedOnIndex(this.selectedChartIndex);
  }

  async filterRegion(l: any) {
    this.chartApplyOnMap=this.getChartApplyOnMapStatus();
    l.disabled = !l.disabled;
    await this.updateChartBasedOnIndex(this.selectedChartIndex);
    this.sortLegend();
  }

  sortLegend() {
    this.chartLegend.sort((a, b) => {
      if (a.disabled === b.disabled)  return a.label.localeCompare(b.label);
      else return a.disabled ? -1 : 1;
    });
  }

  closeInfo() {
    (document.getElementById('info') as HTMLDialogElement).close();
  }

  showInfo(chart: any) {
    this.info = chart.Beschreibung;
    (document.getElementById('info') as HTMLDialogElement).showModal();
  }
  
  formatLabel(value: number): string {
    return `${value}`;
  }

  async updateChartBasedOnIndex(selectedIndex:any) {
    switch (selectedIndex) {
      case 0:
        await this.showGeneralChart();
        break;
      case 1:
        await this.showYearlyChangeChart();
        break;
      case 2:
        await this.showChangeRateChart();
        break;
      case 3:
        await this.showCompareChart();
        break;
      default:
        await this.showGeneralChart();
        break;
    }
  }

  async filter($event: Event, type: string, chart: any) {
    this.chartApplyOnMap=this.getChartApplyOnMapStatus();
    //@ts-ignore
    var value = Number($event.target.ariaValueText);
    if (type == 'timeMin')this.selectedMinYear = value;
    if (type == 'timeMax')this.selectedMaxYear = value;
    if (type == 'valMin')this.selectedMinVal = value;
    if (type == 'valMax')this.selectedMaxVal = value;
    await this.updateChartBasedOnIndex(this.selectedChartIndex);
    return;
  }
 
  generateColorScheme() {
    this.colorScheme = [];
    for (let i = 0; i < this.breaksAmount; i++) {
      const ratio = i / (this.breaksAmount-1);
      const color = this.interpolateColor(this.color1, this.color2, ratio);
      this.colorScheme.push(color);
    }
  }
  
  interpolateColor(color1: string, color2: string, ratio: number): string {
    const hex = (color: string) => parseInt(color.slice(1), 16);
    const r = Math.ceil(((1 - ratio) * (hex(color1) >> 16)) + (ratio * (hex(color2) >> 16)));
    const g = Math.ceil(((1 - ratio) * ((hex(color1) >> 8) & 0x00FF)) + (ratio * ((hex(color2) >> 8) & 0x00FF)));
    const b = Math.ceil(((1 - ratio) * (hex(color1) & 0x0000FF)) + (ratio * (hex(color2) & 0x0000FF)));
    return `#${(0x1000000 + (r * 0x10000) + (g * 0x100) + b).toString(16).slice(1)}`;
  }
  
  openDialogColor() {
    const dialogRef =this.dialog.open(DialogColorComponent,{
      data: {viridisCustom:this.viridisCustom,color1:this.color1,color2:this.color2 ,colorScheme:this.colorScheme,breaksAmount:this.breaksAmount},
      width:'1000px',
      height:'560px',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result && result.setBreaks) {
        this.colorScheme=result.colorScheme;
        this.color1=result.color1;
        this.color2=result.color2;
        this.setColor=true;
        setTimeout(() => {this.chartCase();}, 10);
      }else if (result && !result.setBreaks) {
        this.setColor=false;
        setTimeout(() => {this.chartCase();}, 10);
      }
    });
  }
  
  openDialogYear() {
    const dialogRef =this.dialog.open(DialogYearValueComponent,{
      data: {maxValue:this.maxValue,minValue:this.minValue,maxYear:this.maxYear,minYear:this.minYear,selectedChartIndex:this.selectedChartIndex,
        selectedMaxYearMap:this.selectedMaxYearMap,selectedMinYearMap :this.selectedMinYearMap,selectedMinValMap:this.selectedMinValMap,selectedMaxValMap:this.selectedMaxValMap
      },
      width:'600px',
      height:'320px',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
        this.selectedMinValMap = result.selectedMinVal
        this.selectedMaxValMap = result.selectedMaxVal
        this.selectedMinYearMap = result.selectedMinYear;
        this.selectedMaxYearMap =  result.selectedMaxYear;
        setTimeout(() => {this.chartCase();}, 10);
      }
    });
  }

  openDialogBreaks() {
    const dialogRef =this.dialog.open(DialogBreaksComponent,{
      data: {chosenBreaks:this.chosenBreaks,valuesArray:this.valuesArray,valuesStored:this.valuesStored,valuesBreaks:this.valuesBreaks,breaksAmount:this.breaksAmount,selectedChartObject:this.selectedChartObject},
      width:'700px',
      height:'400px',
      panelClass:'min-max'
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
        this.setBreaks=result.setBreaks;
        this.breaksAmount=parseInt(result.breaksAmount);
        this.valuesArray=result.valuesArray;
        this.valuesStored=result.valuesStored;
        this.valuesBreaks=result.valuesBreaks;
        setTimeout(() => {this.chartCase();}, 10);
      }
    });
  }

  async openDialogData() {
    await this.getIndiYearsAndDataAndUnit2();
    const dialogRef =this.dialog.open(DialogDataComponent,{
      data: {loadingEr:this.loadingEr,selectedChartObject:this.selectedChartObject,dataIndiTableValues:this.dataIndiTableValues,dataIndiTable:this.dataIndiTable},
      width:'1000px',
      height:'700px',
    });
  }
 
  openDialogRegion() {
    const dialogRef =this.dialog.open(DialogSelectionRegionComponent,{
      data: {chartLegendMap:this.chartLegendMap,selectMode:this.selectModeMap},
      width:'600px',
      height:'320px',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
        this.chartLegendMap=result.chartLegendMap,
        this.selectModeMap=result.selectMode
        setTimeout(() => {this.chartCase();}, 10);
      }
    });
  }

  openDialogShare(){

    let needed = false;
    const currentURL = window.location.href;
    //if (currentURL === "http://localhost:4200/dashboards") {
      if (currentURL === "https://webapp.cephlabs.de/dashboards" || currentURL === "http://localhost:4200/dashboards")   needed = false;
      else  needed = true;
      
    if (needed) {
    const dialogRef =this.dialog.open(DialogShareComponent,{
      width:'800px',
      height:'650px',
    });
    dialogRef.afterClosed().subscribe((result:any) => {

    });

  } else this. needDialog=true
  }

  
  async getValuesStored(testData:any,title:any){
    if (this.setColor) this.generateColorScheme()
    let storedArray = testData.shapefiles.data.map((item: any) => {return item.value;});
    this.valuesStored = storedArray.filter((item: any) => {return item !== undefined;});
    this.addShapelayers(testData.shapefiles.data);
    this.addLegendNew(testData,title);
  }

  async chartCase(){
    this.chartApplyOnMap=this.getChartApplyOnMapStatus();
    let requestBuilderMap = new RequestBuilder(this.chartLegendMap,this.selectedMinYearMap,this.selectedMaxYearMap,this.selectedMinValMap, this.selectedMaxValMap,this.selectedShape,this.selectedLevel,this.breaksAmount,this.datahubPath,this.setColor,this.colorScheme,this.storageService,this.selectedChartObject,this.upload,);
    if (this.selectedChartIndex == 0) {
      var testData = (await this.regioService.getGeneralChartData(requestBuilderMap.buildRequestObject())) as any;
      this.getValuesStored(testData,'Allgemein ' + testData.chart.chartData.labels[testData.chart.chartData.labels.length - 1])
    }
     else if (this.selectedChartIndex == 1) {
      var testData = (await this.regioService.getYearlyChangeChartData(requestBuilderMap.buildRequestObject())) as any;
      this.getValuesStored(testData,'Jährliche Veränderungsrate ' + testData.chart.chartData.labels[testData.chart.chartData.labels.length - 1])
    } 
    else if (this.selectedChartIndex == 2) {
      var testData = (await this.regioService.getChangeRateChart(requestBuilderMap.buildRequestObject())) as any;
      this.getValuesStored(testData,testData.chart.chartData.labels[0])
    } 
    else if (this.selectedChartIndex == 3) {
      var testData = (await this.regioService.getCompareChartData(requestBuilderMap.buildRequestObject())) as any;
      this.getValuesStored(testData ,'Vergleich')
    }
  }

  async changeChart(i: any) {
    this.selectedChartObject = i;
    this.selectedButton = i;
    this.chartApplyOnMap=this.getChartApplyOnMapStatus();

    this.addQuelleToIndikator(i)
    if (this.selectedLevel != 3) this.map.setView(this.center, 9);// this sets  always to wittemburg
    else if (this.selectedLevel == 3) this.map.setView(this.centerBundesland, 7);
    this.updateChartBasedOnIndex(this.selectedChartIndex)
    return;
  }

  async changeChartType(selectedIndex: number) {
    this.selectedChartIndex = selectedIndex;
    this.selectedMinYear = 0;
    this.selectedMaxYear = 222000;
    this.selectedMinVal = -1000000;
    this.selectedMaxVal = 1000000;
    this.chartApplyOnMap=this.getChartApplyOnMapStatus();
    if (selectedIndex === 3 || selectedIndex === 2)  this.chartType = 'bar';
    this.setBreaks='Gleichmäßig'//very important so you can switch between 'chips' category
    await this.updateChartBasedOnIndex(selectedIndex);
    return;
  }

  setMinMax(meta: any) {
    this.minValue = meta.minChartValue;
    this.maxValue = meta.maxChartValue;
    this.minYear = meta.minChartYear;
    this.maxYear = meta.maxChartYear;
  }

  changeType(type: string) {
    this.chartType = type;
    this.updateChartBasedOnIndex(this.selectedChartIndex)
  }

  addLegendNew(d: any, chartType: string) {
    this.addLegendTitle(this.selectedChartObject.Name + ' - ' + chartType);
    if (this.breaksAmount > 8) this.breaksAmount = 8
    if (this.legendCluster instanceof L.Control && this.map) this.map.removeControl(this.legendCluster);
    var colorArray = d.shapefiles.meta.colors;
    var breaks = d.shapefiles.meta.breaks;
    
    
    if (this.setColor)  colorArray=this.colorScheme

    if (this.setBreaks == 'Benutzerdefiniert') breaks=this.legendeBenutzerDef();
    else if( this.setBreaks == 'jenks'){
      if (this.valuesStored.length-1===0) alert('Bitte beachten Sie, dass in diesem Fall die Verwendung der Jenks-Verteilung nicht anwendbar ist.');
      else if (this.valuesStored.length <= this.breaksAmount) alert(`Bitte beachten Sie, dass Sie in diesem Fall höchstens ${this.valuesStored.length - 1} eingeben können.`);
      else breaks=this.legendeJenks(); 
    }
    else if(this.setBreaks == 'quantile')breaks=this.legendeQuentile();
    else if(this.setBreaks == 'Gleichmäßig')breaks=this.legendeEqual();

    
    //@ts-ignore
    this.legendCluster = L.control({ position: 'bottomleft' });
    //@ts-ignore
    if (breaks.length>this.breaksAmount) {

      this.legendCluster.onAdd = () => {
        var div = L.DomUtil.create('div', 'info legend');
        var labels = this.setBreaks ? ['<div><b>' + this.setBreaks + '</b></div>']:  ['<div><b>' + 'Gleichmäßig' + '</b></div>'];
        for (var i = 0; i < this.breaksAmount; i++) {
          labels.push(
            '<div style="display: flex;gap: 15px;">' +'<div style="background-color:' + colorArray[i] +
              ';width: 40px;height: 20px "></div>' +'<div>' +'<b> [' +
              breaks[i].toFixed(2) +' , ' +breaks[i + 1].toFixed(2) +') </b>' +'</div>' +'</div>'
          );
        }
        div.innerHTML = labels.join('<br>');
        div.style.backgroundColor = 'rgba(255, 255, 255,0.7)';
        div.style.padding = '5px';
        div.style.width = '170px';
        div.style.border = '1px solid #00695C';
        div.style.borderRadius='8px';
        return div;
      };
      this.legendCluster.addTo(this.map);
    }
    else console.log('display cant activate it for this case!');
    
  }

  legendeBenutzerDef(){
    let combinedValues: any = [];
    this.valuesBreaks.forEach((obj) => {
      combinedValues.push(parseFloat(obj.from));
      combinedValues.push(parseFloat(obj.to));
    });
    let uniqueValues = Array.from(new Set(combinedValues));
    uniqueValues.sort((a: any, b: any) => a - b);
    return uniqueValues;
  }

  legendeJenks(){
    const brew = new classyBrew();
    brew.setSeries(this.valuesStored);
    brew.setNumClasses(parseInt(this.breaksAmount));
    brew.setColorCode("BuGn");
    brew.classify('jenks');
    const values: number[] = brew.getBreaks();
    return values
  }

  legendeQuentile(){
    const brew = new classyBrew();
    brew.setSeries(this.valuesStored);
    brew.setNumClasses(parseInt(this.breaksAmount));
    brew.setColorCode("BuGn");
    brew.classify('quantile');
    const values: number[] = brew.getBreaks();
    return values
  }

  legendeEqual(){
    var a = Math.min.apply(null, this.valuesStored)
    , b = Math.max.apply(null, this.valuesStored)
    , c = []
    , d = a
    , e = (b - a) / this.breaksAmount;
  for (var i = 0; i <= this.breaksAmount; i++)
      c[i] = d,
      d += e;
  return c[this.breaksAmount] = b,

  c.sort(function(a, b) {
      return a - b
  }),
  c
  }

  addShapelayers(data: any) {
    if(this.setBreaks == '')data = data.reverse();
    else if (this.setBreaks == 'Gleichmäßig') data=this.shapeEqual(data)
    else if (this.setBreaks == 'Benutzerdefiniert') data=this.shapeBenutzerDef(data);
    else if (this.setBreaks == 'jenks') data=this.shapeJenksOrQuen(data,'jenks');
    else if (this.setBreaks == 'quantile') data=this.shapeJenksOrQuen(data,'quantile');
    
    this.layers = [];
    data.forEach((element: any) => {
      var fillOpacity = 1;
      if (element.value === '' || element.value === null || element.value === undefined) {
        fillOpacity = 0.1;
        element.value = 'Kein Wert!';
      }
      
      var weight = 1.5;
      var color = 'black';
      if (this.selectedShape == element.Object_Key) {
        fillOpacity = 1;
        weight = 5;
        color = 'red';
      }

      var l = L.geoJSON(element.geometry, {
        style: {
          fillColor: element.color,
          fillOpacity: fillOpacity,
          weight: weight,
          color: color,
        },
        onEachFeature: (f, l) => {
          l.bindPopup("<span class='span2'> " +element.Object_Nam +' </span> <br>' +"<span class='span0'> " +element.value +' </span> <br>');
        },


      });

      l.on('mouseover', () => {
        l.bindPopup("<span class='span2'> " +element.Object_Nam +' </span> <br>' +"<span class='span2'> " +element.value +' </span> <br>').openPopup();
      });

      l.on('mouseout', () => { l.closePopup();});

      l.on('click', async () => {
     
        
        this.selectedShape = element.Object_Key;
        await this.updateChartBasedOnIndex(this.selectedChartIndex);
      });
      this.layers.push(l);
     // console.log(this.layers);
      // this.test.push({
      //   leaflet:l
      //   raw:element
      // })
      
    });
  }

  shapeBenutzerDef(data:any){
    var colorArray= this.getColorArray();
    let combinedValues: any = [];
    this.valuesBreaks.forEach((obj) => {
      combinedValues.push(parseFloat(obj.from));
      combinedValues.push(parseFloat(obj.to));
    });
    let uniqueValues: any;
    uniqueValues = Array.from(new Set(combinedValues));
    uniqueValues.sort((a: any, b: any) => a - b);
    uniqueValues;
    
    data.forEach((shape: any) => {
      for (var i = 1; i < uniqueValues.length; i++) {
        if (
          uniqueValues[i - 1] <= shape.value &&
          shape.value <= uniqueValues[i]
        ) {
          shape.color = colorArray[i - 1];
        }
      }
    });
    return data
  }

  shapeJenksOrQuen(data:any,distribution:string){
    var colorArray= this.getColorArray();
    const brew = new classyBrew();
    brew.setSeries(this.valuesStored);
    brew.setNumClasses(parseInt(this.breaksAmount));
    brew.setColorCode("BuGn");
    brew.classify(distribution);
    const values: number[] = brew.getBreaks();

    data.forEach((shape: any) => {
      for (var i = 1; i < values.length; i++) {
        if (
          values[i - 1] <= shape.value &&
          shape.value <= values[i]
        ) {
          shape.color = colorArray[i - 1];
        }
      }
    });
    return data
  }

  shapeEqual(data:any){
  let colorArray= this.getColorArray();
  let values = this.legendeEqual();
  data.forEach((shape: any) => {
    for (var i = 1; i < values.length; i++) {
      if (
        values[i - 1] <= shape.value &&
        shape.value <= values[i]
      ) {
        shape.color = colorArray[i - 1];
      }
    }
  });
  return data
  }

  getColorArray(){
  if (this.setColor) return this.colorScheme
  else{
    var colorArrayAll = this.viridisCustom;
    var colorArray = ['#440154'];
  
    if (this.breaksAmount > 2) {
      var step = Math.max(Math.floor((colorArrayAll.length - 2) / (this.breaksAmount - 2)),1);
      for (var i = 1; i < this.breaksAmount - 1; i++) {
        var colorIndex = i * step + 1;
        if (colorIndex >= colorArrayAll.length - 1) colorIndex = colorArrayAll.length - 2;
        colorArray.push(colorArrayAll[colorIndex]);
      }
      colorArray.push('#FDE725');
    } else if (this.breaksAmount === 2) colorArray.push('#FDE725');
  
    colorArray.push('black');
    return colorArray
  }
 }

  async auswahl() {
    //this.layers = []
  
    this.auswahlClicked = true;
    this.dataEr = false;
    this.dataIm = false;

    if ((this.datahubPath == 2 || this.datahubPath == 3) && !this.uploded) {
      this.upload = this.dataEx.slice(1).map((row) => ({
        Gebietsname: row[0],
        years: row[1],
        data: row[2],
      }));
    }
    var indi: any[] = [];
    (document.getElementById('map-container')as HTMLElement).style.width = '40%';
    (document.getElementById('chart-container')as HTMLElement).style.width = '50%';
    (document.getElementById('map-container')as HTMLElement).style.height = '575px';
    (document.getElementById('chart-container')as HTMLElement).style.height = '575px';
    setTimeout(() => {this.map.invalidateSize();}, 400);

    this.chartReady = true;
    (document.getElementById('dialog')as HTMLDialogElement).close();
 
    // (document.getElementById('dialog1')as HTMLDivElement).style.display='none';
    var topics = this.regioMetaData.filter((t: { checked: boolean }) => t.checked == true);
    topics.forEach((topic: { data: any[] }) => {
      var selIndis = topic.data.filter((t: { checked: boolean }) => t.checked == true);
      indi = indi.concat(selIndis);
    });
    
    indi.sort((a, b) => b.checkedTimestamp - a.checkedTimestamp);
 
    await this.setIndicator(indi[0]);

    if (this.selectedLevel != 3) this.map.setView(this.center, 9);// this sets  always to wittenbrg?
    else if (this.selectedLevel == 3) this.map.setView(this.centerBundesland, 7);

    this.selectedButton=indi[0]
    
    
   this.switchToNewestTab();
    this.auswahlEnded=true;
    this.newSelection=true;


    this.addQuelleToIndikator(indi[0]);
  }

  onCheckboxChange(topic: any, indi: any, checked: boolean){
    indi.checked = checked;
    if (checked) indi.checkedTimestamp = Date.now(); 
    else  delete indi.checkedTimestamp; 
    
    indi.color = topic.color; 
    this.checkIndicators(topic); 
  }

  async setIndicator(indicator: any) {
    var newBodyInd = {
      Beschreibung: this.inputBesch,
      Ist_Daten: 'test2 ',
      Name: this.inputIndi,
      Source: ' test4',
      Tabellen_ID: 0,
      checked: true,
      color: '#cd5380',
      unit: this.inputUnit,
    };
    this.selectedChartObject = indicator ? indicator : newBodyInd;    
    await this.showGeneralChart();

  }

  /*
  *shoe data table in dialog for 'erweitern'
  */
  async getIndiYearsAndDataAndUnit() {
    this.loadingEr = false;

    var indi: any[] = [];
    var topics = this.regioMetaData.filter((t: { checked: boolean }) => t.checked == true);
    topics.forEach((topic: { data: any[] }) => {
      var selIndis = topic.data.filter((t: { checked: boolean }) => t.checked == true);
      indi = indi.concat(selIndis);
    });
    this.indiForErTable = indi[0];
    var disableRegions = this.chartLegend.filter((c) => c.disabled == true);
    var disableKeys: any[] = [];
    disableRegions.forEach((el) => disableKeys.push(el.Object_Key));
    if (!this.selectedShape) this.selectedShape = this.storageService.getObject().Object_Key;
    var selObjKey = this.storageService.getObject().Object_Key;
    if (this.selectedLevel == 3) selObjKey = '15';
    var body = {
      objectKey: selObjKey,
      focusedKey: this.selectedShape,
      tableKey: indi[0].Source + indi[0].Tabellen_ID,
      admin_level: Number(this.selectedLevel),
      minTime: 0,
      maxTime: 222000,
      minValue: -1000000,
      maxValue: 1000000,
      filterKeys: disableKeys,
      datahubPath: 1,
    };
    this.indiData = await this.regioService.getGeneralChartData(body);
    const uniqueGebietsname = [...new Set(this.dataEx.map((item) => item[0]))];
    this.filteredIndiData = this.indiData.chart.chartData.datasets.filter((dataset: any) => uniqueGebietsname.includes(dataset.label));
    this.filteredIndiData.forEach((dataset: any) => {dataset.years = this.indiData.chart.chartData.labels;});
    this.createInfosTable(this.indiForErTable,1);
  }

/*
*show data table for downloading in main page
*/
  async getIndiYearsAndDataAndUnit2() {
    var newBodyInd = {
      Beschreibung: this.inputBesch,
      Ist_Daten: 'test2 ',
      Name: this.inputIndi,
      Source: ' test4',
      Tabellen_ID: 0,
      checked: true,
      color: '#cd5380',
      unit: this.inputUnit,
    };
// very important step to chanege between indicators
    if (!this.selectedChartObject ) this.selectedChartObject = newBodyInd;    
    let body = this.createRequestBody().buildRequestObject();
    if (this.selectedChartIndex == 0) this.indiData = await this.regioService.getGeneralChartData(body);
    else if (this.selectedChartIndex == 1) this.indiData = await this.regioService.getYearlyChangeChartData(body); 
    else if (this.selectedChartIndex == 2) this.indiData = await this.regioService.getChangeRateChart(body); 
    else if (this.selectedChartIndex == 3) this.indiData = await this.regioService.getCompareChartData(body); 

    this.filteredIndiData = this.indiData.chart.chartData.datasets;
    this.filteredIndiData.forEach((dataset: any) => {dataset.years = this.indiData.chart.chartData.labels;});
    this.createInfosTable(this.selectedChartObject,this.selectedChartIndex);
  }

  createInfosTable(obj:any,chartNmber:number){
    const dataSource: any = [];
    this.filteredIndiData.forEach((dataset: any) => {
      const gebietsname = dataset.label;
      dataset.data.forEach((data: any, index: any) => {
        let einheit;
        if (chartNmber == 0 && obj.unit) einheit = obj.unit;
        else if ( chartNmber == 1 || chartNmber ==2) einheit = '%';
        else if (chartNmber == 3) einheit = 'Durchschnitt ' + this.indiData.chart.chartMeta.minChartYear + ' - ' + this.indiData.chart.chartMeta.maxChartYear;
        else einheit = 'Keine Einheit in der Datenbank registriert.'; 

        dataSource.push({
          gebietsname,
          year: this.indiData.chart.chartData.labels[index],
          data: data,
          einheit:einheit
        });
      });
    });
    this.dataIndiTable = dataSource;
    this.dataIndiTable = this.dataIndiTable.filter((item: any) => {return item.data != undefined;});

    const minYear = Math.min(...this.dataIndiTable.map((entry: any) => entry.year));
    const maxYear = Math.max(...this.dataIndiTable.map((entry: any) => entry.year));
    const minData = Math.min(...this.dataIndiTable.map((entry: any) => entry.data));
    const maxData = Math.max(...this.dataIndiTable.map((entry: any) => entry.data)).toFixed(2);
    const totalData = this.dataIndiTable.reduce((sum: any, entry: any) => sum + entry.data,0);
    const averageData = (totalData / this.dataIndiTable.length).toFixed(2);
    this.dataIndiTableValues = {
      MinYear: minYear,
      MaxYear: maxYear,
      MinData: minData,
      MaxData: maxData,
      AverageData: averageData,
    };
    this.minYearData2 = this.dataIndiTableValues.MaxYear + 1;
    this.onSliderChange();
    this.nextYear = this.minYearData2;
    this.loadingEr = true;
  }

  async themenauswahl() {
    this.showDetail = true;
    this.showAnal = false;
    this.spinner = true;
    this.topicsLoaded = true;
    await this.setLayer();

    this.regioMetaData = [];
    var regioMeta: any = [];

    // var randomElement = this.keys[Math.floor(Math.random() * this.keys.length)];
    // var randomElement1 =this.keys[Math.floor(Math.random() * this.keys.length)];
    // var randomElement2 =this.keys[Math.floor(Math.random() * this.keys.length)];
    //this.keys = [randomElement, randomElement1, randomElement2];
    
    var regioData = await this.regioService.getRegioDataByKey(this.keys) as any;
    this.regioData = regioData;
    regioMeta = await this.regioService.getIndicatorMeta();
    
    
    
    var indi = regioData.map(function (el: { Key: any }) {return el.Key;});
    
    const uniqueIndi = [...new Set(indi)];
    this.regioMetaData = regioMeta.data;
    

    regioMeta.data.forEach((bereich: { data: any[]; visible: boolean }) => {
      var filtered = bereich.data.filter((indi) => {
        var key = indi.Source + indi.Tabellen_ID;
        // console.log(key);
        // console.log("uniqueIndi:", uniqueIndi);
        return uniqueIndi.indexOf(key) > -1;
      });
      bereich.data = filtered;
      if (bereich.data.length > 0) bereich.visible = true;
      else bereich.visible = false;
    });
    
    this.regioMetaData = regioMeta.data.filter((el:any) => el.visible);
    this.topicsLoaded = false;

    if (this.selectedLevel==3)  this.topicsLandkreis=this.regioMetaData;
    else if (this.selectedLevel==4)  this.topicsVerbandsgemeinde=this.regioMetaData;
    else if (this.selectedLevel==5)  this.topicsGemeinde=this.regioMetaData;

    
    return;
  }

  removeIndi(indi:any,topic:any){
    indi.checked=false;
    const hasCheckedIndicators = topic.data.some((item: any) => item.checked);
    topic.checked = hasCheckedIndicators;
    this.auswahl();
  }

  closeTab(topic: any): void {
    topic.data.forEach((indi: any) => {
      indi.checked = false;
    });

    topic.checked = false;

   this.auswahl();
  }

openWarningDialog(topic: any): void {
  const dialogRef = this.dialog.open(WarningDialogThemaDatahubComponent, {
    data: {topicName:topic.Bereich},
    width: '400px',
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {
      this.closeTab(topic); // Call your method to remove the topic and indicators
    }
  });
}


  close() {
   ( document.getElementById('dialog')as HTMLDialogElement).close();
  //  (document.getElementById('dialog1')as HTMLDivElement).style.display='none';

    this.showDetail = false;
    this.showIndi = false;
    this.showAnal = true;

    this.showExport = false;
    this.showUpload = false;
    this.customData = false;
    this.customPath = false;
    this.dataEr = false;
    this.dataIm = false;
    this.downloaded = false;
    this.stepCounter = 0;
    this.chooseThema = false;
    this.importValues = false;
    this.uploded = false;
    this.ThemaViaEr = false;
    //this.selectedChartObject = undefined;
    //this.datahubPath = 0;
    //this.selectedLevel = 0;

    this.firstRender = false;
    this.firstRenderVerband = false;
    this.firstRenderGemeinde = false;
    //auswahlClicked=false;
    this.loading = true;

    this.selectLandkreis.forEach((land) => {this.selectedLands[land] = false;});
    this.verband.forEach((land: any) => {this.selectedLandsVerband[land.Object_Nam] = false;});
    this.gemeinde.forEach((land: any) => {this.selectedLandsGmeinde[land.Object_Nam] = false;});
  }

  onChange(t: any, $event: MatCheckboxChange) {
    if ($event.checked) {
      var randomColor = Math.floor(Math.random() * 16777215).toString(16);
      t.color = '#' + randomColor;
      t.checked = true;
      t.data.forEach((indi: { color: any }) => {
        indi.color = t.color;
      });
      if (this.selectedTopic.filter((el: { Bereich: any }) => el.Bereich == t.Bereich) == 0)   this.selectedTopic.push(t);
    } else t.checked = false;  
  }

  showModal(step: any) {
    switch (step) {
      case 0:
        this.showAnal = true;
        this.showIndi = false;
        this.showDetail = false;

        this.showExport = false;
        this.showUpload = false;
        break;
      case 1:
        this.showAnal = false;
        this.showDetail = true;
        this.showIndi = false;

        this.showExport = false;
        this.showUpload = false;
        break;
      case 2:
        this.showAnal = false;
        this.showDetail = true;
        this.showIndi = true;

        this.showExport = false;
        this.showUpload = false;
        break;
    }

    if (this.storageService.getObject().Logo_URL == '') {
      alert('Bitte zuerst Landkreis festlegen!');
      this.router.navigateByUrl('/start');
    }
    else setTimeout(() => {(document.getElementById('dialog')as HTMLDialogElement).showModal(); }, 300);
  }

  addLegendTitle(title: any) {
    if (this.legend instanceof L.Control) this.map.removeControl(this.legend);
    //@ts-ignore
    this.legend = L.control({ position: 'topright' });
    //@ts-ignore
    this.legend.onAdd = () => {
      var div = L.DomUtil.create('div', 'info legend');
      var labels = ['<div style="background-color: rgba(255, 255, 255, 0.5) ; padding: 2px; border-radius: 4px; width: 90%" >  <h1> ' +title +' </h1> </div>',];
      div.innerHTML = labels.join('<br>');
      return div;
    };
    this.legend.addTo(this.map);
  }

  onMapReady(map: any) {
    this.map = map;
    this.setLayer();
    this.map.on('zoomend', () => {
      // alert(this.map.getZoom()) 
    });
  }

  async setLayer() {
    this.spinner = true;
    var keys: any[] = [];
    this.layers = [];
    var bundesland = await this.regioService.getShapefileByIdAndLevel(this.storageService.getObject().Object_Key.substring(0, 2),1) as any;
    this.bundeslandLayer = L.geoJSON(bundesland[0][0].geometry, {
      style: {
        fillColor: 'white',
        fillOpacity: 0,
        weight: 4,
        color: 'black',
      },

      onEachFeature: (f, l) => {
        var out: string[] = [];
        out.push('Name : ' + bundesland[0][0].Object_Nam + '<br>');
        keys.push(bundesland[0][0].Object_Key);
        l.bindPopup("<span class='span2'> " + bundesland[0][0].Object_Nam + ' </span> ');
      },
    });

    this.layers.push(this.bundeslandLayer);

    var bounds = this.bundeslandLayer.getBounds();
    this.centerBundesland = bounds.getCenter();
    this.map.setView(this.centerBundesland, 7);
    var objKey = this.storageService.getObject().Object_Key;
    if (this.selectedLevel == 3) objKey = '15';

    var res = await this.regioService.getShapefileByIdAndLevel(objKey,this.selectedLevel) as any;
    this.presLayer = res[0];
    
    // res[0].push(bundesland[0][0]);
    this.keys = [];
    keys = [];
    
    res[0].forEach((element: any) => {
      var l = L.geoJSON(element.geometry, {
        style: {
          fillColor: 'white',
          fillOpacity: 0.2,
          weight: 1.5,
          color: 'black',
        },
        onEachFeature: (f, l) => {
          var out: string[] = [];
          out.push('Name : ' + element.Object_Nam + '<br>');
          keys.push(element.Object_Key);
          l.bindPopup(
            "<span class='span2'> " + element.Object_Nam + ' </span> '
          );
        },
      });

      l.on('mouseover', () => {
        l.bindPopup(
          "<span class='span2'> " + element.Object_Nam + ' </span> <br>'
        ).openPopup();
        this.storageService.setActiveLabel(element.Object_Nam);
      });

      l.on('mouseout', () => {  l.closePopup();});

      this.layers.push(l);
      //var bounds2 = l.getBounds();

      var bounds = l.getBounds();
      if(this.storageService.getObject().Object_Key == element.Object_Key) this.center = bounds.getCenter();

      this.keys = keys;
    });
    this.spinner = false;

    setTimeout(() => {
      if (this.selectedLevel != 3) this.map.setView(this.center, 9);
    }, 2000);
  }

  onFileChange(event: any) {
    this.uploded = true;
    const file = event.target.files[0];
    const reader = new FileReader();
    this.fileName = file.name;
    reader.readAsBinaryString(file);
    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      workbook.SheetNames.forEach((sheet) => {
        this.upload = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        this.convertToJson = JSON.stringify(this.upload, undefined, 4);
      });
    };
    reader.readAsArrayBuffer(file);
  }

  exportData() {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataEx);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'eigene_daten.xlsx');
    this.downloaded = true;
  }

  onSliderChange() {
    if (this.land == 3) this.generateDataArrayCommon(this.selectLandkreis, this.selectedLands, this.minYearData, this.maxYearData, this.minYearData2, this.maxYearData2);
    if (this.land == 4) this.generateDataArrayCommon(this.verband, this.selectedLandsVerband, this.minYearData, this.maxYearData, this.minYearData2, this.maxYearData2);
    if (this.land == 5) this.generateDataArrayCommon(this.gemeinde, this.selectedLandsGmeinde, this.minYearData, this.maxYearData, this.minYearData2, this.maxYearData2);
  }

  initMapForImportOrErweitern(mapHtml:string){
     return new MapLibreGL.Map({
      container: mapHtml,
      style:
        'https://api.maptiler.com/maps/streets/style.json?key=eRJsnpLp5PIaYJVCxumc',
      center: [12.1506056, 51.9490063],
      zoom: 5.8,
      maxBounds: [
        [7.46328899335299, 50.42654727434322],
        [16.038967853182015, 53.496028621118114],
      ],
    });
  }

  initSachsenAnhaltForAll(map:any,layer:string,source:string){
    this.simulationService.getSachsenAnhaltShape().subscribe((sachsenAnhaltShape) => {
      this.sachsenAnhaltShape = sachsenAnhaltShape;
      map.on('load', () => {
        map?.addSource(source, {
          type: 'geojson',
          data: this.sachsenAnhaltShape,
        });
        map?.addLayer({
          id: layer,
          type: 'line',
          source: source,
          layout: {},
          paint: {
            'line-color': 'black',
            'line-opacity': 1,
            'line-width': 3,
          },
        });
      });
    });
  }

  initVerOrGem(map:any,sourceLayer:string){
    this.regioService.getShapefileByIdAndLevel(this.storageService.getObject().Object_Key, 3).then((res: any) => {
      map.on('load', () => {
        map?.addSource(sourceLayer, {
          type: 'geojson',
          data: res[0][0].geometry,
        });
        map.addLayer({
          id: sourceLayer,
          type: 'line',
          source: sourceLayer,
          layout: {},
          paint: {
            'line-color': 'black',
            'line-opacity': 1,
            'line-width': 3,
          },
        });
        const coordinates = res[0][0].geometry.coordinates;
        const bounds = coordinates.reduce(
          (bounds: any, polygonCoords: any) => {
            polygonCoords.forEach((coord: any) => {
              bounds.extend(coord);
            });
            return bounds;
          },
          new MapLibreGL.LngLatBounds()
        );

        map.fitBounds(bounds, {
          padding: 20,
          maxZoom: 7.4,
        });
      });
    });
  }

  generateDataArrayCommon(dataSource: any[], selectedLands: any, minYearData: number, maxYearData: number, minYearData2: number, maxYearData2: number) {
    this.dataEx = [['Gebietsname', 'years', 'data']];
    if (this.importValues) {
      dataSource.forEach((landkreis: any) => {
        if (selectedLands[landkreis.Object_Nam || landkreis]) { 
          for (let year = minYearData; year <= maxYearData; year++) {
            this.dataEx.push([landkreis.Object_Nam || landkreis, year]); 
          }
        }
      });
    } else {
      dataSource.forEach((landkreis: any) => {
        if (selectedLands[landkreis.Object_Nam || landkreis]) { 
          for (let year = minYearData2; year <= maxYearData2; year++) {
            this.dataEx.push([landkreis.Object_Nam || landkreis, year]);
          }
        }
      });
    }
  }
/////////SA///////////  
  initLayersSALK() {
    this.regioService.getShapefilesLandkreisSA().then((res: any) => {
      const totalShapes = res[0].length;
      this.checkLayer = res[0];

      for (let i = 0; i < res[0].length; i++) {
        this.mapSmall.on('load', () => {
          this.mapSmall.addSource(`LK-source${i}`, {
            type: 'geojson',
            data: res[0][i],
          });
          this.mapSmall.addLayer({
            id: `LK-layer${i}`,
            type: 'fill',
            source: `LK-source${i}`,
            layout: {},
            paint: {
              'fill-color': 'white',
              'fill-opacity': 0.2,
              'fill-antialias': true,
              'fill-outline-color': 'black',
            },
          });

          this.shapesRendered++;
          if (this.shapesRendered === totalShapes) {
            this.loading = false;
          }

          this.mapSmall.on('mouseenter', `LK-layer${i}`, (e: any) => {
            this.mapSmall.setPaintProperty(
              `LK-layer${i}`,
              'fill-color',
              'blue'
            );
          });

          this.mapSmall.on('mouseleave', `LK-layer${i}`, (e: any) => {
            const clickedDistrict = res[0][i].properties.Object_Name;

            if (!this.selectedLands[clickedDistrict]) {
              this.mapSmall.setPaintProperty(
                `LK-layer${i}`,
                'fill-color',
                'white'
              );
            }
          });

          this.mapSmall.on('click', `LK-layer${i}`, async (e: any) => {
            const clickedDistrict = res[0][i].properties.Object_Name;

            if (this.selectedLands[clickedDistrict]) {
              this.mapSmall.setPaintProperty(
                `LK-layer${i}`,
                'fill-color',
                'white'
              );
              this.selectedLands[clickedDistrict] = false;
            } else {
              this.mapSmall.setPaintProperty(
                `LK-layer${i}`,
                'fill-color',
                'blue'
              );
              this.selectedLands[clickedDistrict] = true;
            }

            this.getCheckedCheckboxes();
          });
        });
      }
    });
    this.shapesRendered = 0;
    this.loading = true;
  }

  getCheckedCheckboxes() {
    const checkedCheckboxes = this.selectLandkreis.filter((land) => this.selectedLands[land]);
    this.generateDataArrayCommon(this.selectLandkreis, this.selectedLands, this.minYearData, this.maxYearData, this.minYearData2, this.maxYearData2);
  }

  toggleLayer(land: string) {
    const clickedDistrict = land;
    const index = this.checkLayer.findIndex((layer: any) => layer.properties.Object_Name === clickedDistrict);
    if (index !== -1) {
      if (this.selectedLands[clickedDistrict]) {
        this.mapSmall.setPaintProperty(
          `LK-layer${index}`,
          'fill-color',
          'blue'
        );
        this.selectedLands[clickedDistrict] = true;
      } else {
        this.mapSmall.setPaintProperty(
          `LK-layer${index}`,
          'fill-color',
          'white'
        );
        this.selectedLands[clickedDistrict] = false;
      }
      this.getCheckedCheckboxes();
    }
  }

  ///////// verband /////////////////
  initLayersSALKV() {
    this.regioService.getShapefileByIdAndLevel(this.storageService.getObject().Object_Key, 4).then((res: any) => {
        const totalShapes = res[0].length;
        this.checkLayer = res[0];

        for (let i = 0; i < res[0].length; i++) {
          this.mapSmallVerband.on('load', () => {
            this.mapSmallVerband.addSource(`V-source${i}`, {
              type: 'geojson',
              data: res[0][i].geometry,
            });
            this.mapSmallVerband.addLayer({
              id: `V-layer${i}`,
              type: 'fill',
              source: `V-source${i}`,
              layout: {},
              paint: {
                'fill-color': 'white',
                'fill-opacity': 0.4,
                'fill-antialias': true,
                'fill-outline-color': 'black',
              },
            });

            this.shapesRendered++;

            if (this.shapesRendered === totalShapes) {
              this.loading = false;
            }

            this.mapSmallVerband.on('mouseenter', `V-layer${i}`, (e: any) => {
              this.mapSmallVerband.setPaintProperty(
                `V-layer${i}`,
                'fill-color',
                'blue'
              );
            });

            this.mapSmallVerband.on('mouseleave', `V-layer${i}`, (e: any) => {
              const clickedDistrict = res[0][i].Object_Nam;

              if (!this.selectedLandsVerband[clickedDistrict]) {
                this.mapSmallVerband.setPaintProperty(
                  `V-layer${i}`,
                  'fill-color',
                  'white'
                );
              }
            });

            this.mapSmallVerband.on('click', `V-layer${i}`, async (e: any) => {
              const clickedDistrict = res[0][i].Object_Nam;

              if (this.selectedLandsVerband[clickedDistrict]) {
                this.mapSmallVerband.setPaintProperty(
                  `V-layer${i}`,
                  'fill-color',
                  'white'
                );
                this.selectedLandsVerband[clickedDistrict] = false;
              } else {
                this.mapSmallVerband.setPaintProperty(
                  `V-layer${i}`,
                  'fill-color',
                  'blue'
                );
                this.selectedLandsVerband[clickedDistrict] = true;
              }

              this.getCheckedCheckboxesVerband();
            });
          });
        }
      });
    this.shapesRendered = 0;
    this.loading = true;
  }

  getCheckedCheckboxesVerband() {
    const checkedCheckboxes = this.verband.filter((land: any) => this.selectedLandsVerband[land.Object_Nam]);
    this.generateDataArrayCommon(this.verband, this.selectedLandsVerband, this.minYearData, this.maxYearData, this.minYearData2, this.maxYearData2);
  }

  toggleLayerVerband(land: string) {
    const clickedDistrict = land;
    const index = this.verband.findIndex((layer: any) => layer.Object_Nam === clickedDistrict);
    if (index !== -1) {
      if (this.selectedLandsVerband[clickedDistrict]) {
        this.mapSmallVerband.setPaintProperty(
          `V-layer${index}`,
          'fill-color',
          'blue'
        );
        this.selectedLandsVerband[clickedDistrict] = true;
      } else {
        this.mapSmallVerband.setPaintProperty(
          `V-layer${index}`,
          'fill-color',
          'white'
        );
        this.selectedLandsVerband[clickedDistrict] = false;
      }
      this.getCheckedCheckboxesVerband();
    }
  }
  /////Gemeinde///
  initLayersSALKG() {
    this.regioService.getShapefileByIdAndLevel(this.storageService.getObject().Object_Key, 5).then((res: any) => {
        const totalShapes = res[0].length;
        this.checkLayer = res[0];

        for (let i = 0; i < res[0].length; i++) {
          this.mapSmallGmeinde.on('load', () => {
            this.mapSmallGmeinde.addSource(`G-source${i}`, {
              type: 'geojson',
              data: res[0][i].geometry,
            });
            this.mapSmallGmeinde.addLayer({
              id: `G-layer${i}`,
              type: 'fill',
              source: `G-source${i}`,
              layout: {},
              paint: {
                'fill-color': 'white',
                'fill-opacity': 0.4,
                'fill-antialias': true,
                'fill-outline-color': 'black',
              },
            });

            this.shapesRendered++;

            if (this.shapesRendered === totalShapes) {
              this.loading = false;
            }

            this.mapSmallGmeinde.on('mouseenter', `G-layer${i}`, (e: any) => {
              this.mapSmallGmeinde.setPaintProperty(
                `G-layer${i}`,
                'fill-color',
                'blue'
              );
            });

            this.mapSmallGmeinde.on('mouseleave', `G-layer${i}`, (e: any) => {
              const clickedDistrict = res[0][i].Object_Nam;

              if (!this.selectedLandsGmeinde[clickedDistrict]) {
                this.mapSmallGmeinde.setPaintProperty(
                  `G-layer${i}`,
                  'fill-color',
                  'white'
                );
              }
            });

            this.mapSmallGmeinde.on('click', `G-layer${i}`, async (e: any) => {
              const clickedDistrict = res[0][i].Object_Nam;

              if (this.selectedLandsGmeinde[clickedDistrict]) {
                this.mapSmallGmeinde.setPaintProperty(
                  `G-layer${i}`,
                  'fill-color',
                  'white'
                );
                this.selectedLandsGmeinde[clickedDistrict] = false;
              } else {
                this.mapSmallGmeinde.setPaintProperty(
                  `G-layer${i}`,
                  'fill-color',
                  'blue'
                );
                this.selectedLandsGmeinde[clickedDistrict] = true;
              }

              this.getCheckedCheckboxesGmeinde();
            });
          });
        }
      });
    this.shapesRendered = 0;
    this.loading = true;
  }

  getCheckedCheckboxesGmeinde() {
    const checkedCheckboxes = this.gemeinde.filter((land: any) => this.selectedLandsGmeinde[land.Object_Nam]);
    this.generateDataArrayCommon(this.gemeinde, this.selectedLandsGmeinde, this.minYearData, this.maxYearData, this.minYearData2, this.maxYearData2);
  }

  toggleLayerGmeinde(land: string) {
    const clickedDistrict = land;
    const index = this.gemeinde.findIndex((layer: any) => layer.Object_Nam === clickedDistrict);
    if
     (index !== -1) {
      if (this.selectedLandsGmeinde[clickedDistrict]) {
        this.mapSmallGmeinde.setPaintProperty(
          `G-layer${index}`,
          'fill-color',
          'blue'
        );
        this.selectedLandsGmeinde[clickedDistrict] = true;
      } else {
        this.mapSmallGmeinde.setPaintProperty(
          `G-layer${index}`,
          'fill-color',
          'white'
        );
        this.selectedLandsGmeinde[clickedDistrict] = false;
      }
      this.getCheckedCheckboxesGmeinde();
    }
  }

  selectMap() {
    if (this.land == 3) this.initLayersMapbox();
    else if (this.land == 4) this.initMapVer();
    else if (this.land == 5) this.initMapGem();
  }

 initLayersMapbox() {
    if (this.land == 3) {
      setTimeout(async () => {
        if (this.showExport) {
          if (!this.firstRender) {
            this.mapSmall=this.initMapForImportOrErweitern('mapSmall');
            this.initSachsenAnhaltForAll(this.mapSmall,'SA-layer1','SA-source1');
            this.initLayersSALK();
            this.firstRender = true;
          }
        }
      }, 100);
    }
  }

  async initMapVer() {
    setTimeout(async () => {
      if (!this.firstRenderVerband) {
        this.verband = await this.regioService.getShapefileByIdAndLevel(this.storageService.getObject().Object_Key,4);
        this.verband = this.verband[0];
        this.verband.forEach((land: any) => {this.selectedLandsVerband[land.Object_Nam] = false;});
        this.mapSmallVerband=this.initMapForImportOrErweitern('mapSmallVerband')
        this.initSachsenAnhaltForAll(this.mapSmallVerband,'SA-layer1','SA-source1');
        this.initVerOrGem(this.mapSmallVerband,'SABund');
        this.initLayersSALKV();
        this.firstRenderVerband = true;
      }
    }, 100);
  }

  async initMapGem() {
    setTimeout(async () => {
      if (!this.firstRenderGemeinde) {
        this.gemeinde = await this.regioService.getShapefileByIdAndLevel(this.storageService.getObject().Object_Key,5);
        this.gemeinde = this.gemeinde[0];
        this.gemeinde.forEach((land: any) => {this.selectedLandsGmeinde[land.Object_Nam] = false;});
       // this.gemeinde.sort((a:any, b:any) => a.Object_Nam.localeCompare(b.Object_Nam));
        this.mapSmallGmeinde=this.initMapForImportOrErweitern('mapSmallGmeinde')
        this.initSachsenAnhaltForAll(this.mapSmallGmeinde,'SAG','SAG');
        this.initVerOrGem(this.mapSmallGmeinde,'SAVer')
        this.initLayersSALKG();
        this.searchForGemeinde=this.gemeinde
        this.firstRenderGemeinde = true;
      }
    }, 100);
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.chartLegend.filter((option) => option.label.toLowerCase().includes(filterValue));
  }

  startTopic() {
    this.filteredTopics = [...this.regioMetaData];    
    this.regioMetaData.forEach((element: any) => {
      element.data.forEach((item: any) => {
        item.visible = true;
      });
    });
    this.filteredIndi = [...this.regioMetaData];
  }

  filterTopics() {
    const filterValue = this.topicFilter.toLowerCase();
    this.filteredTopics = this.regioMetaData.filter((topic: any) =>topic.Bereich.toLowerCase().includes(filterValue));
  }

  filterIndi() {
    const filterValue = this.indiFilter.toLowerCase();
    this.filteredIndi.forEach((topic: any) => {
      topic.data.forEach((indi: any) => {
        if (indi.Name.toLowerCase().includes(filterValue)) indi.visible = true;
        else indi.visible = false;
      });
    });
    this.filteredTopics = this.filteredIndi.filter((topic: any) =>topic.data.some((indi: any) => indi.visible));
  }

   getTopicOfIndi(searchedTopic:any,searchedIndi:any){
    searchedTopic.showIndicators=true;
    searchedIndi.checked = true;
    searchedTopic.checked = true;
   }

  toggleIndicators(topic: any) {
    topic.showIndicators = !topic.showIndicators;
  }

  checkOnlyOne(topic: any, selectedIndi: any) {
    for (let otherTopic of this.filteredTopics) {
      if (otherTopic !== topic) {
        for (let indi of otherTopic.data) {
          indi.checked = false;
        }
        otherTopic.checked = false;
      }
    }
    for (let indi of topic.data) {
      if (indi !== selectedIndi) {
        indi.checked = false;
      }
    }
  }

  checkIndicators(topic: any) {
    topic.checked = topic.data.some((indi: any) => indi.checked);
  }

  data(event: ClipboardEvent) {
    this.uploded = false;
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData!.getData('text');
    let row_data = pastedText.split('\n');
    for (let index = 0; index < row_data.length - 1; index++) {
      let pastedData = parseFloat(row_data[index].trim());
      this.dataEx[index + 1].push(pastedData);
    }
    this.dataSource = this.dataEx;
  }

  isInputThemaInRegioMetaData(): boolean {
    return this.regioMetaData.some((topic: any) => topic.Bereich === this.inputThema);
  }

  removeThemaChecked() {
    this.selectedTopic = [];
    for (let topic of this.regioMetaData) {
      topic.checked = false;
    }
  }
  
  resetInputs(topic: any) {
    this.inputThema = '';
    this.inputIndi = '';
    let otherTopic = topic;
    for (let topic of this.regioMetaData) {
      if (otherTopic !== topic)  topic.checked = false;
    }
  }

  doNotShowDropdown() {
    setTimeout(() => {this.showDropdown = false;}, 300);
  }

  doNotShowDropdown2() {
    setTimeout(() => { this.showDropdown2 = false;}, 300);
  }

  async startTopic2() {
    await this.themenauswahl();
    this.startTopic();
  }

  captureAndRestoreLegend(legend: any, callback: any) {
    var originalStyle = legend.getAttribute('style');
    legend.style.height = 'auto';
    legend.style.overflowY = 'visible';
    html2canvas(legend).then((canvas) => {
      legend.setAttribute('style', originalStyle);
      callback(canvas);
    });
  }

  downloadChart() {
    var legend = document.getElementById('chartLegend');
    this.captureAndRestoreLegend(legend, (legendCanvas: any) => {
      var chartCanvas = document.getElementById('canvasGeneral') as HTMLCanvasElement;
      var combinedCanvas = document.createElement('canvas');
      combinedCanvas.width = Math.max(chartCanvas.width, legendCanvas.width);
      combinedCanvas.height = chartCanvas.height + legendCanvas.height;
      var ctx = combinedCanvas.getContext('2d');
      ctx!.drawImage(chartCanvas, 0, 0);
      ctx!.drawImage(legendCanvas, 0, chartCanvas.height);
      var downloadLink = document.createElement('a');
      downloadLink.href = combinedCanvas.toDataURL('image/png');
      downloadLink.download = 'combinedChartAndLegend.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }

  scrollToElement(event: any): void {
    const selectedLabel = event.option.value;
    const elementId = 'legendItem-' + selectedLabel;
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      element.classList.add('highlight');
      element.classList.remove('normal');
      setTimeout(() => {
        element.classList.replace('highlight', 'normal');
      }, 1000);
    }
  }

  selectGemeinde(){
    this.gemeinde.forEach((land: any) => {
      this.selectedLandsGmeinde[land.Object_Nam] = true;
      this.toggleLayerGmeinde(land.Object_Nam);
    });
  }

  unselectGemeinde(){
    this.gemeinde.forEach((land: any) => {
      this.selectedLandsGmeinde[land.Object_Nam] = false;
      this.toggleLayerGmeinde(land.Object_Nam);
    });
  }

  searchedGemeinde(){
    this.searchForGemeinde= this.gemeinde.filter((land:any) =>
      land.Object_Nam.toLowerCase().includes(this.filterGemeinde.toLowerCase())
    );
  }

  displayRadar(){
    const n = document.getElementById('mapChart');
    n!.classList.remove('fade-in');
    n!.classList.add('fade-out');
    setTimeout(() => {n!.style.display = 'none';}, 500);
    
    const i = document.getElementById('indicatorRadar');
    i!.classList.remove('fade-in');
    i!.classList.add('fade-in');
    setTimeout(() => {i!.style.display = 'flex';}, 500);
    
    this.currentState={
      chartLegend:this.chartLegend,
      selectedMinYear:this.selectedMinYear,
      selectedMaxYear:this.selectedMaxYear,
      selectedMinVal:-1000000,
      selectedMaxVal:1000000,
      selectedShape:this.selectedShape,
      selectedLevel:this.selectedLevel,
      breaksAmount:this.breaksAmount,
      datahubPath:this.datahubPath,
      setColor:this.setColor,
      colorScheme:this.colorScheme,
      storageService:this.storageService,
      selectedChartObject:this.selectedChartObject,
      upload:this.upload,
    }

  }

  openDialogRadar(){
    if (this.selectedLevel==3) this.regioMetaData=this.topicsLandkreis;
    else if (this.selectedLevel==4)  this.regioMetaData=this.topicsVerbandsgemeinde;
    else if (this.selectedLevel==5)  this.regioMetaData=this.topicsGemeinde;

    const dialogRef =this.dialog.open(DialogRadarComponent,{
      data: {chartLegend:this.chartLegend,maxYear:this.maxYear,minYear:this.minYear, selectedChartIndex:this.selectedChartIndex, regioMetaData:this.regioMetaData,selectedLevel:this.selectedLevel},
      width:'1500px',
      height:'900px',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
        if(this.selectedChartIndex===1) this.lastClickedAction= 'Jährliche Veränderungsrate';
        if(this.selectedChartIndex===0) this.lastClickedAction= 'Allgemein';
        this.radarActivated=true
        this.selectedRegionForRadar=result.chartLegend;
        this.activateRadar= result.indicators;
        this.radarYears = [{
          radarMinYear: result.minYear,
          radarYear: result.selectedYear,
          radarStartIntervalle: this.minYear, 
          radarEndIntervalle: this.maxYear,
          //yearsArray:result.yearsArray
        }];
        this.radarChartIndex=this.selectedChartIndex
        this.displayRadar();
        setTimeout(() => {this.childComponent.displayIndi();}, 1000);
      }
    });
  }

  displayMapChart(){
    this.radarActivated=false;

    const i = document.getElementById('indicatorRadar');
    i!.classList.remove('fade-in');
    i!.classList.add('fade-out');
    setTimeout(() => {i!.style.display = 'none';}, 500); 

    const n = document.getElementById('mapChart');
    n!.classList.remove('fade-out');
    n!.classList.add('fade-in');
    setTimeout(() => {n!.style.display = 'flex';}, 500);
  }

  async changeChartRadar(selectedIndex:number){
    setTimeout(() => {
      switch (selectedIndex) {
        case 0:
          this.lastClickedAction= 'Allgemein';
          this.radarChartIndex = 0
          setTimeout(() => {this.childComponent.displayIndi();}, 1000);
          break;
        case 1:
          this.lastClickedAction= 'Jährliche Veränderungsrate';
          this.radarChartIndex = 1
          setTimeout(() => {this.childComponent.displayIndi();}, 1000);
          break;
        default:
          this.lastClickedAction= 'Allgemein';
          this.radarChartIndex = 0
          setTimeout(() => {this.childComponent.displayIndi();}, 1000);
          break;
      }
    }, 300);
  }


  async openDialogVersion(){
    const dialogRef =this.dialog.open(DialogVersionComponent,{
      width:'600px',
      height:'700px',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
    });
  }
  
  async openDialogSave() {

    let dummyRegioMetaDataTopics = this.regioMetaData.filter((item:any) => item.checked);
    let dummyRegioMetaDataIndicators = await dummyRegioMetaDataTopics.map((topic:any) => ({
        ...topic,
        data: topic.data.filter((item:any) => item.checked)
    }));

    let dummytopicsLandkreisTopics= this.topicsLandkreis.filter((item:any) => item.checked);
    let dummytopicsLandkreis = await dummytopicsLandkreisTopics.map((topic:any) => ({
        ...topic,
        data: topic.data.filter((item:any) => item.checked)
    }));

    let dummytopicsVerbandsgemeindeTopics = this.topicsVerbandsgemeinde.filter((item:any) => item.checked);
    let dummytopicsVerbandsgemeinde= await dummytopicsVerbandsgemeindeTopics.map((topic:any) => ({
        ...topic,
        data: topic.data.filter((item:any) => item.checked)
    }));

    let dummytopicsGemeindeTopics = this.topicsGemeinde.filter((item:any) => item.checked);
    let dummytopicsGemeinde = await dummytopicsGemeindeTopics.map((topic:any) => ({
        ...topic,
        data: topic.data.filter((item:any) => item.checked)
    }));
      
    const dialogRef =this.dialog.open(DialogSaveComponent,{
      data: this.createToBeSavedBody(dummyRegioMetaDataIndicators,dummytopicsLandkreis,dummytopicsVerbandsgemeinde,dummytopicsGemeinde),
      width:'500px',
      height:'300px',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
    });


  }

 
  openDialogGetDash() {
    const dialogRef =this.dialog.open(DialogGetDashComponent,{
      width:'800px',
      height:'auto',
    });
  }

createToBeSavedBody(RegioMetaDataIndicators:any,dummytopicsLandkreis:any,dummytopicsVerbandsgemeinde:any,dummytopicsGemeinde:any){
  return{
    chartLegend : this.chartLegend,
    selectedMinYear : this.selectedMinYear,
    selectedMaxYear : this.selectedMaxYear,
    selectedMinVal :this.selectedMinVal,
    selectedMaxVal : this.selectedMaxVal,
    selectedShape : this.selectedShape,
    selectedLevel : this.selectedLevel,
    breaksAmount : this.breaksAmount,
    datahubPath : this.datahubPath,
    setColor :this.setColor,
    colorScheme : this.colorScheme,
    //regioMetaData:this.regioMetaData,
    dataEx:this.dataEx,
    selectedChartObject:this.selectedChartObject,
    selectedChartIndex: this.selectedChartIndex,
    chartType:this.chartType,
    auswahlEnded:this.auswahlEnded,
    radarActivated:this.radarActivated,
    auswahlClicked:this.auswahlClicked,
    minValueAll:this.minValue ,
    maxValueAll:this.maxValue,
    minYearAll:this.minYear ,
    maxYearAll:this.maxYear ,
    //filteredTopics: this.filteredTopics,
    //filteredIndi: this.filteredIndi,
    editPen:this.editPen,
    chartApplyOnMap: this.chartApplyOnMap,
    chartLegendMap:this.chartLegendMap,
    setBreaks : this.setBreaks, 
    valuesBreaks : this.valuesBreaks,
    valuesStored : this.valuesStored,
    valuesArray : this.valuesArray,
    color1 : this.color1,
    color2 : this.color2,
    selectedMaxValMap : this.selectedMaxValMap,
    selectedMinValMap : this.selectedMinValMap,
    selectedMaxYearMap : this.selectedMaxYearMap,
    selectedMinYearMap : this.selectedMinYearMap, 
    selectModeMap : this.selectModeMap,
    dummyRegioMetaDataIndicators:RegioMetaDataIndicators,
    inputBesch: this.inputBesch,
    inputIndi:this.inputIndi,
    inputUnit: this.inputUnit,
    inputThema: this.inputThema,
    Object: this.storageService.getObject(),
    topicsLandkreis:dummytopicsLandkreis,
    topicsVerbandsgemeinde:dummytopicsVerbandsgemeinde,
    topicsGemeinde:dummytopicsGemeinde,
    selectedButton :this.selectedButton,
    neighbours:this.neighbours,
  }
}

  async showSavedChart() {
    var requestBody = this.createRequestBody().buildRequestObject();
    var testData:any
    var yAxisLabel:any
    if (this.selectedChartIndex == 0)  testData = await this.regioService.getGeneralChartData(requestBody);
    else if (this.selectedChartIndex == 1)  testData = await this.regioService.getYearlyChangeChartData(requestBody); 
    else if (this.selectedChartIndex == 2)  testData = await this.regioService.getChangeRateChart(requestBody); 
    else if (this.selectedChartIndex == 3)  {
      testData = await this.regioService.getCompareChartData(requestBody);
      testData.chart.chartData.datasets.forEach((item: any) => {
        item.skipNull = true;
        item.data.forEach((element: any, index: number) => {
          if (element === 0) {
            item.data[index] = null;
            if (index != 1) {
              item.barThickness = 10;
            }
          }
        });
      });
      yAxisLabel = 'Durchschnitt ' +testData.chart.chartMeta.minChartYear +' - ' + testData.chart.chartMeta.maxChartYear;
    } 

    if (this.selectedChartIndex == 0) yAxisLabel = this.selectedChartObject? this.selectedChartObject.unit: ' ';
    else if (this.selectedChartIndex == 1)  yAxisLabel = '%';
    else if (this.selectedChartIndex == 2)  yAxisLabel = testData.chart.chartData.labels[0] + 'in %';

    //import for the first click on edit in map
    let storedArray = testData.shapefiles.data.map((item: any) => {return item.value;});
    this.valuesStored = storedArray.filter((item: any) => {return item !== undefined;});

    this.chartGeneral?.destroy();
    var chartDisplay = this.chartTemplate(testData,yAxisLabel)
    setTimeout(() => {this.chartGeneral = new Chart('canvasGeneral', chartDisplay);}, 10);
    this.chartLegend = testData.chart.chartMeta.regions;
    this.chartCase(); 
  }


  
  async bindToMap() {
    this.chartApplyOnMap = true;
  }

  unbindFromMap() {
    this.chartApplyOnMap = false;
  }

  getChartApplyOnMapStatus(): boolean {
    return this.chartApplyOnMap;
  }



  async loadDataRegio2(saved:any){
    this.regioMetaData=saved.regioMetaData;
    this.dataEx=saved.dataEx;
    this.selectedChartIndex=saved.selectedChartIndex;
    this.selectedChartObject = saved.selectedChartObject;
    this.chartType=saved.chartType;
    this.auswahlEnded=saved.auswahlEnded
    this.radarActivated=saved.radarActivated
    this.minValue=saved.minValueAll
    this.maxValue=saved.maxValueAll
    this.minYear =saved.minYearAll  
    this.maxYear =saved.maxYearAll 
    this.editPen=saved.editPen
    this.auswahlClicked=saved.auswahlClicked;
    this.chartApplyOnMap=saved.chartApplyOnMap;
    this.chartLegendMap=saved.chartLegendMap;
    this.setBreaks = saved.setBreaks;
    this.valuesBreaks = saved.valuesBreaks;
    this.valuesStored = saved.valuesStored;
    this.valuesArray = saved.valuesArray;
    this.color1 = saved.color1;
    this.color2 = saved.color2;
    this.selectedMaxValMap = saved.selectedMaxValMap;
    this.selectedMinValMap = saved.selectedMinValMap;
    this.selectedMaxYearMap = saved.selectedMaxYearMap;
    this.selectedMinYearMap = saved.selectedMinYearMap;
    this.selectModeMap = saved.selectModeMap;
    this.chartLegend = saved.chartLegend;
    this.selectedMinYear = saved.selectedMinYear ;
    this.selectedMaxYear = saved.selectedMaxYear;
    this.selectedMinVal = saved.selectedMinVal;
    this.selectedMaxVal = saved.selectedMaxVal;
    this.selectedShape = saved.selectedShape;
    this.selectedLevel = saved.selectedLevel;
    this.breaksAmount = saved.breaksAmount;
    this.datahubPath = saved.datahubPath;
    this.setColor = saved.setColor;
    this.colorScheme = saved.colorScheme;
    this.upload = saved.upload;

    this.inputBesch= saved.inputBesch
    this.inputIndi= saved.inputIndi
    this.inputUnit= saved.inputUnit
    this.inputThema= saved.inputThema

    this.topicsLandkreis= saved.topicsLandkreis;
    this.topicsVerbandsgemeinde= saved.topicsVerbandsgemeinde;
    this.topicsGemeinde= saved.topicsGemeinde;
    this.selectedButton = saved.selectedButton ;

    this.neighbours=saved.neighbours;
  
    

    if (this.selectedLevel==3) this.regioMetaData=this.topicsLandkreis;
    else if (this.selectedLevel==4)  this.regioMetaData=this.topicsVerbandsgemeinde;
    else if (this.selectedLevel==5)  this.regioMetaData=this.topicsGemeinde;

    this.filteredTopics = [...this.regioMetaData];
    this.regioMetaData.forEach((element: any) => {
      element.data.forEach((item: any) => {
        item.visible = true;
      });
    });
    this.filteredIndi = [...this.regioMetaData];
    await this.setLayer();
    
    //auswahl
    if ((this.datahubPath == 2 || this.datahubPath == 3) && !this.uploded) {
      this.upload = this.dataEx.slice(1).map((row) => ({
        Gebietsname: row[0],
        years: row[1],
        data: row[2],
      }));
    }
  
    (document.getElementById('map-container')as HTMLElement).style.width = '40%';
    (document.getElementById('chart-container')as HTMLElement).style.width = '50%';
    (document.getElementById('map-container')as HTMLElement).style.height = '575px';
    (document.getElementById('chart-container')as HTMLElement).style.height = '575px';
    this.newSelection=true
    setTimeout(() => {this.map.invalidateSize();}, 400);

    this.chartReady = true;
    
    //setindicator
    var newBodyInd = {
      Beschreibung: this.inputBesch,
      Ist_Daten: 'test2 ',
      Name: this.inputIndi,
      Source: ' test4',
      Tabellen_ID: 0,
      checked: true,
      color: '#cd5380',
      unit: this.inputUnit,
    };
    //this.selectedChartObject = indicator ? indicator : newBodyInd;
    await this.showSavedChart();
    
    this.chartApplyOnMap = true; // so the 
    this.selectedButton=this.selectedChartObject 
    
    this.addQuelleToIndikator(this.selectedChartObject);
    

  }

  getCheckedCount(data: any[]): number {
    return data.filter(item => item.checked).length;
  }

  jumpToNextArea(){  
    if (this.selectedLevel==3) this.selectedLevel=4;
    else if (this.selectedLevel==4) this.selectedLevel=5;
  }

  jumpToPreviousArea(){
    if (this.selectedLevel==4) this.selectedLevel=3;
    else if (this.selectedLevel==5) this.selectedLevel=4;
  }

  startTopicForArea(){
    
    if (this.selectedLevel==3) this.regioMetaData=this.topicsLandkreis;
    else if (this.selectedLevel==4)  this.regioMetaData=this.topicsVerbandsgemeinde;
    else if (this.selectedLevel==5)  this.regioMetaData=this.topicsGemeinde;

    this.startTopic()
  }

  //// mohammed Report Tool ////
  public loadingN: boolean = false;
  private subscriptions: Subscription = new Subscription();
  private apiChartData: any;

  private async getApiChartData(): Promise<void> {

    const requestBody = this.createRequestBody().buildRequestObject();
    switch (this.selectedChartIndex) {
      case 1:
        this.apiChartData = await this.regioService.getYearlyChangeChartData(requestBody);
        break;
      case 2:
        this.apiChartData = await this.regioService.getChangeRateChart(requestBody);
        break;
      case 3:
        this.apiChartData = await this.regioService.getCompareChartData(requestBody);
        break;
      default:
        this.apiChartData = await this.regioService.getGeneralChartData(requestBody);
    }
  }

  public async showReport(): Promise < void > {
    const topic = this.regioMetaData.find((r: any) => r.checked);
    if(topic) {
      const dialogRef = this.dialog.open(SelectIndikatorenModalComponent, {
        width: '1500px',
        height: 'auto',
        data: topic.data
      });
  
      this.subscriptions.add(dialogRef.componentInstance.confirm.subscribe((indikatoren: any[]) => {
        this.showPDF(indikatoren);
      }));
    }
  }

  public async showPDF(indikatoren: any[]): Promise < void > {
    const data: any[] = [];
    const selectedChart = this.selectedChartObject;

    this.loadingN = true;

    for (var i = 0; i < indikatoren.length; i++) {
      data.push(await this.getIndikatorReport(indikatoren[i]));
      if (i === indikatoren.length - 1) {
        const ReportDialogRef = this.dialog.open(ReportDialogComponent, {
          width: '1500px',
          height: 'auto',
          data: data
        });

        this.subscriptions.add(ReportDialogRef.afterOpened().subscribe(() => {
          this.loadingN = false;
        }));

        this.subscriptions.add(ReportDialogRef.afterClosed().subscribe(async () => {
          this.loadingN = true;
          this.selectedChartObject = selectedChart;
          await this.showChart();
          this.loadingN = false;
        }));
      }
    }
  }

  private async showChart(): Promise<void> {
    switch (this.selectedChartIndex) {
      case 0:
        await this.showGeneralChart();
        break;
      case 1:
        await this.showYearlyChangeChart();
        break;
      case 2:
        await this.showChangeRateChart();
        break;
      case 3:
        await this.showCompareChart();
        break;
      default:
        await this.showGeneralChart();
        break;
    }
  }

  private async getIndikatorReport(indi: any): Promise<any> {
    let mapImg: any = undefined;
    let chartImg: any = undefined;

    if(this.selectedChartObject !== indi) {
      this.selectedChartObject = indi;
      await this.showChart();
    }

    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });

    if(indi.withMap) mapImg = await this.getMapImg();

    if(indi.withChart) chartImg = await this.getChartImg();

    return {
      name: this.selectedChartObject.Name,
      filterName: this.getFilterName(),
      minYear: this.selectedMinYear !== 0 ? this.selectedMinYear : this.minYear,
      maxYear: this.selectedMaxYear !== 222000 ? this.selectedMaxYear : this.maxYear,
      chartLegend: this.getActivateLegend(),
      chartImg: chartImg,
      mapImg: mapImg
    }
  }

  private getChartImg(): Promise < string | undefined > {
    return new Promise((resolve, reject) => {
      const domChart = document.getElementById('chart');
      if (domChart) {
        htmlToImage.toPng(domChart,{skipFonts:true})
          .then(function (dataUrl) {
            resolve(dataUrl);
          })
          .catch(function (error) {
            console.error('Error:', error);
            reject();
          });
      }
    });

  }

  private getMapImg(): Promise < string | undefined > {
    return new Promise((resolve, reject) => {
      const domMap = document.getElementById('map2');
      if (domMap) {
        htmlToImage.toPng(domMap,{skipFonts:true})
          .then(function (dataUrl) {
            resolve(dataUrl);
          })
          .catch(function (error) {
            console.error('Error:', error);
            reject();
          });
      }
    });

  }

  private getFilterName(): string {
    switch (this.selectedChartIndex) {
      case 1:
        return "Jährliche Veränderungsrate";
      case 2:
        return "Änderungsrate zwischen Zeitpunkten";
      case 3:
        return "Vergleich";
      default:
        return "Allgemein";
    }
  }

  public getActivateLegend(): any[] {
    return this.chartLegend.filter((l: any) => !l.disabled);
  }

 
 }
